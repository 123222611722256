import firebase from "firebase";

const firebaseApp = firebase.initializeApp({

    apiKey: "AIzaSyDYNEf1rXj9Bga7en71V_Fr4ym83C2Rtyg",
    authDomain: "assct-986a3.firebaseapp.com",
    databaseURL: "https://assct-986a3.firebaseio.com",
    projectId: "assct-986a3",
    storageBucket: "assct-986a3.appspot.com",
    messagingSenderId: "557654427560",
    appId: "1:557654427560:web:7bfae12abc5da6b1b27f9d"
});

const db = firebaseApp.firestore();
export {db};
