import React from 'react';


import '../App.css';
import './Footer.css'
import {MyContext} from "../../config/Theme-Context";
import {NavLink} from "react-router-dom";

function Footer() {

    return (
        <div className="App-Component footer">

            <div className="d-none d-md-block d-lg-block footerBody">
                <div className="row">

                    <MyContext.Consumer>
                        {({jointFooter}) => {

                            if (jointFooter === false) {
                                return ""
                            } else {
                                let data = jointFooter.content[0];
                                return (
                                    <div className="col-4 order-second ">
                                        <div className={"footerContent ASSCTFooter"}>
                                            <NavLink exact to="/">
                                                <h6><u>ASSCT</u></h6>
                                            </NavLink>
                                            For more information, contact:<br/>
                                            {data.ContactName}<br/>
                                            {data.ContactPosition}<br/>
                                            <b>Phone: </b>{data.Phone}<br/>
                                            <b>Email: </b> <a href={"mailto:" + data.Email}>{data.Email}</a><br/>
                                            {data.LocationName}<br/> {data.Address}, <br/>{data.CityAndState}<br/>
                                        </div>
                                    </div>
                                )
                            }
                        }
                        }
                    </MyContext.Consumer>

                    <MyContext.Consumer>
                        {({FLFooter}) => {

                            if (FLFooter === false) {
                                return ""
                            } else {
                                let data = FLFooter.content[0];
                                return (
                                    <div className="col-4 order-second ">
                                        <div className={"footerContent ASSCTFooter"}>
                                            <NavLink exact to="/FLDivision">
                                                <h6><u>Florida Division</u></h6>
                                            </NavLink>
                                            For more information, contact:<br/>
                                            {data.ContactName}<br/>
                                            <b>Phone: </b>{data.Phone}<br/>
                                            <b>Email: </b> <a href={"mailto:" + data.Email}>{data.Email}</a><br/>
                                            {data.LocationName}<br/> {data.Address}, <br/>{data.CityAndState}<br/>
                                        </div>
                                    </div>
                                )
                            }
                        }
                        }
                    </MyContext.Consumer>

                    <MyContext.Consumer>
                        {({LAFooter}) => {
                            if (LAFooter === false) {
                                return ""
                            } else {
                                let data = LAFooter.content[0];
                                return (
                                    <div className="col-4 order-second ">
                                        <div className={"footerContent ASSCTFooter"}>
                                            <NavLink exact to="/LADivision">
                                                <h6><u>Louisiana Division</u></h6>
                                            </NavLink>
                                            For more information, contact:<br/>
                                            {data.ContactName}<br/>
                                            <b>Phone: </b>{data.Phone}<br/>
                                            <b>Email: </b> <a href={"mailto:" + data.Email}>{data.Email}</a><br/>
                                            {data.LocationName}<br/> {data.Address}, <br/>{data.CityAndState}<br/>
                                        </div>
                                    </div>
                                )
                            }
                        }
                        }
                    </MyContext.Consumer>


                </div>
                <div className="copyright">
                    <p>&copy; 2020</p>
                </div>
            </div>

            {/*Show on mobile only*/}
            <div className="row d-md-none d-lg-none">

                <div className="footerElement col-6">
                    <div className="page-footer">
                        <div className={"footerContent floridaFooter"}>
                            <MyContext.Consumer>
                                {({FLFooter}) => {
                                    if (FLFooter === false) {
                                        return ""
                                    } else {
                                        let data = FLFooter.content[0];
                                        return (
                                            <>
                                                <NavLink exact to="/FLDivision">
                                                    <h6 className={"footerSectionTitle"}><u>
                                                        Florida Division
                                                    </u>
                                                    </h6>
                                                </NavLink>
                                                {data.ContactName}<br/>
                                                {returnPosition(data.ContactPosition)}
                                                <b>Phone: </b>{data.Phone}<br/>
                                                <b>Email: </b> <a href={"mailto:" + data.Email}>{data.Email}</a><br/>
                                                {data.LocationName}<br/> {data.Address}, <br/>{data.CityAndState}<br/>
                                            </>
                                        )
                                    }
                                }
                                }

                            </MyContext.Consumer>
                        </div>
                    </div>
                </div>

                <div className="footerElement col-6 order-second">
                    <div className={"footerContent louisianaFooter"}>
                        <MyContext.Consumer>
                            {({LAFooter}) => {
                                if (LAFooter === false) {
                                    return ""
                                } else {
                                    let data = LAFooter.content[0];

                                    return (
                                        <>
                                            <NavLink exact to="/LADivision">
                                                <h6 className={"footerSectionTitle"}><u>Louisiana
                                                    Division</u>
                                                </h6>
                                            </NavLink>
                                            {data.ContactName}<br/>
                                            <b>Phone: </b>{data.Phone}<br/>
                                            <b>Email: </b> <a href={"mailto:" + data.Email}>{data.Email}</a><br/>
                                            {data.LocationName}<br/> {data.Address}, <br/>{data.CityAndState}<br/>
                                        </>
                                    )
                                }
                            }
                            }
                        </MyContext.Consumer>
                    </div>
                </div>

                <div className="footerElement col-12 order-second">
                    <div className={"footerContent ASSCTFooter"}>
                        <MyContext.Consumer>
                            {({jointFooter}) => {
                                if (jointFooter === false) {
                                    return ""
                                } else {
                                    let data = jointFooter.content[0];

                                    return (
                                        <>
                                            <NavLink exact to="/">
                                                <h6 className={"footerSectionTitle"}><u>ASSCT</u></h6>
                                            </NavLink>
                                            {data.ContactName}<br/>
                                            <b>Phone: </b>{data.Phone}<br/>
                                            <b>Email: </b> <a href={"mailto:" + data.Email}>{data.Email}</a><br/>
                                            {data.LocationName}<br/> {data.Address}, <br/>{data.CityAndState}<br/>
                                        </>
                                    )
                                }
                            }
                            }

                        </MyContext.Consumer>
                    </div>
                </div>

            </div>
        </div>
    );
}

function returnPosition(position) {
    if (position === "" || position === undefined || position === null) {
        return ""
    } else return (position + <br/>)
}

export default Footer;
