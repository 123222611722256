import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom'

import './App.scss';

import Header from "./HeaderComponent/Header";
import Footer from "./FooterComponent/Footer";
import Home from "./HomeComponent/Home";
import Journals from "./JournalsComponent/Journals";
import Officers from "./OfficersComponent/Officers";
import HonoraryMembers from "./HonoraryMembersComponent/HonoraryMembers";
import Meetings from "./MeetingsComponent/Meetings";
import {MyContext} from "../config/Theme-Context";
import {db} from "../config/config";
import Membership from "./MembershipComponent/Membership";
import FLDivision from "./DivisionsComponent/FLDivisions";
import LADivision from "./DivisionsComponent/LADivision";
import JointMeetings from "./MeetingsComponent/JointMeetings";
import DonationComponent from "./DonationComponent/DonationComponent";
import {JournalDB} from "../config/JournalsConf";
import {FLdb} from "../config/FLConfig";
import {LAdb} from "../config/LAConf";

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            "slideShow": false,
            journals: false,
            officers: false,
            meetings: false,
            newestVolume: false,
            ISSCT_Officers: false,
            life_members: false,
            jointFooter: false,
            FLFooter: false,
            LAFooter: false,
            journalContact: false,
            constitutionLink: false,
            LAMemberShipForm: false,
            FLMemberShipForm: false,
            sweetLinks: false,
            honoraryMembers: false,
            inMemoriam: false
        };
    }

    async componentDidMount() {
        this.fetchSiteData();
    }

    render() {
        return (
            <MyContext.Provider value={this.state}>
                <BrowserRouter>
                    <div className="entirePage transition">
                        <Header/>
                        <div id="mainContent">
                            <Switch>
                                <Route path="/" exact component={Home}/>
                                <Route path="/journals" exact
                                       render={(props) => <Journals {...props} year={this.state["newestVolume"]}/>}/>
                                <Route path="/officers" exact component={Officers}/>
                                <Route path={"/honoraryMembers"} exact component={HonoraryMembers}/>
                                <Route path={"/meetings"} exact component={Meetings}/>
                                <Route path={"/membership"} exact component={Membership}/>
                                <Route path={"/FLDivision"} exact component={FLDivision}/>
                                <Route path={"/LADivision"} exact component={LADivision}/>
                                <Route path={"/JointMeetings"} exact component={JointMeetings}/>
                                <Route path={"/Donate"} exact component={DonationComponent}/>
                            </Switch>
                        </div>
                        <div id="footer" className="page">
                            <Footer/>
                        </div>
                    </div>
                </BrowserRouter>
            </MyContext.Provider>
        );
    }


    fetchSiteData() {
        let meetings = {};
        db.collection("Meetings").get().then((snapshot) => {
            snapshot.docs.forEach(doc => {
                    meetings[doc.id] = doc.data();
                }
            );
            this.setState({meetings: meetings});

        });

        let officers = {};


        db.collection("Florida_Executive_Officers").get().then((snapshot) => {
            snapshot.docs.forEach(doc => {
                if (!(doc.id in officers)) {
                    officers[doc.id] = [];
                }
                officers[doc.id].push(doc.data())
            });

        });

        db.collection("Louisiana_Executive_Officers").get().then((snapshot) => {
            snapshot.docs.forEach(doc => {
                if (!(doc.id in officers)) {
                    officers[doc.id] = [];
                }
                officers[doc.id].push(doc.data())
            });
            this.setState({officers: officers})

        });

        let journals = {};
        JournalDB.collection("Journals").get().then((snapshot) => {
            snapshot.docs.forEach(doc => {
                journals[doc.id] = [];
                journals[doc.id].push(doc.data())
            });
            this.setState({journals: journals});
            Object.keys(journals).map(year => {
                if (parseInt(year.split(" ")[1])) {
                    this.state["newestVolume"] = parseInt(year.split(" ")[1]);
                }
            })
        });

        let homepage = [];
        db.collection("HomePageSlideShow").doc("Images").get().then((images) => {
            homepage.push(images.data());
            this.setState({slideShow: homepage})
        });

        let ISSCT_Officers = {};
        db.collection("ISSCT_Officers").get().then((snapshot) => {
            snapshot.docs.forEach(doc => {
                if (!(doc.id in ISSCT_Officers)) {
                    ISSCT_Officers[doc.id] = [];
                }
                ISSCT_Officers[doc.id].push(doc.data())
            });
            this.setState({ISSCT_Officers: ISSCT_Officers})
        });

        let life_members = {};
        db.collection("Honorary_Life_Members").get().then((snapshot) => {
            snapshot.docs.forEach(doc => {
                if (!(doc.id in life_members)) {
                    life_members[doc.id] = [];
                }
                life_members[doc.id].push(doc.data())
            });
            this.setState({life_members: life_members})
        });

        let jointFooter = {};
        db.collection("Footer").get().then((snapshot) => {
            snapshot.docs.forEach(doc => {
                if (!(doc.id in jointFooter)) {
                    jointFooter[doc.id] = [];
                }
                jointFooter[doc.id].push(doc.data())
            });
            this.setState({jointFooter: jointFooter})
        });

        let honoraryMembers = {};
        db.collection("Honorary Members").get().then((snapshot) => {
            snapshot.docs.forEach(doc => {
                if (!(doc.id in honoraryMembers)) {
                    honoraryMembers[doc.id] = [];
                }
                honoraryMembers[doc.id].push(doc.data())
            });
            this.setState({honoraryMembers: honoraryMembers})
        });

        let inMemoriam = {};
        db.collection("In Memoriam").get().then((snapshot) => {
            snapshot.docs.forEach(doc => {
                if (!(doc.id in inMemoriam)) {
                    inMemoriam[doc.id] = [];
                }
                inMemoriam[doc.id].push(doc.data())
            });
            this.setState({inMemoriam: inMemoriam})
        });

        let FLFooter = {};
        FLdb.collection("Footer").get().then((snapshot) => {
            snapshot.docs.forEach(doc => {
                if (!(doc.id in FLFooter)) {
                    FLFooter[doc.id] = [];
                }
                FLFooter[doc.id].push(doc.data())
            });
            this.setState({FLFooter: FLFooter})
        });

        let LAFooter = {};
        LAdb.collection("Footer").get().then((snapshot) => {
            snapshot.docs.forEach(doc => {
                if (!(doc.id in LAFooter)) {
                    LAFooter[doc.id] = [];
                }
                LAFooter[doc.id].push(doc.data())
            });
            this.setState({LAFooter: LAFooter})
        });

        let JournalContact = {};
        JournalDB.collection("JournalContact").get().then((snapshot) => {
            snapshot.docs.forEach(doc => {
                if (!(doc.id in JournalContact)) {
                    JournalContact[doc.id] = [];
                }
                JournalContact[doc.id].push(doc.data())
            });
            this.setState({journalContact: JournalContact})
        });

        let constitutionLink = {};
        db.collection("Constitution").get().then((snapshot) => {
            snapshot.docs.forEach(doc => {
                if (!(doc.id in constitutionLink)) {
                    constitutionLink[doc.id] = [];
                }
                constitutionLink[doc.id].push(doc.data())
            });
            this.setState({constitutionLink: constitutionLink.Constitution[0].link})
        });

        let sweetLinks = {};
        db.collection("Sweet Links").get().then((snapshot) => {
            snapshot.docs.forEach(doc => {
                if (!(doc.id in sweetLinks)) {
                    sweetLinks[doc.id] = [];
                }
                sweetLinks[doc.id].push(doc.data())
            });
            this.setState({sweetLinks: sweetLinks})
        });

        let FLMemberShipForm = {};
        FLdb.collection("MembershipForm").get().then((snapshot) => {
            snapshot.docs.forEach(doc => {
                if (!(doc.id in FLMemberShipForm)) {
                    FLMemberShipForm[doc.id] = [];
                }
                FLMemberShipForm[doc.id].push(doc.data())
            });
            this.setState({FLMemberShipForm: FLMemberShipForm.form[0].link})
        });

        let LAMemberShipForm = {};
        LAdb.collection("MembershipForm").get().then((snapshot) => {
            snapshot.docs.forEach(doc => {
                if (!(doc.id in LAMemberShipForm)) {
                    LAMemberShipForm[doc.id] = [];
                }
                LAMemberShipForm[doc.id].push(doc.data())
            });
            this.setState({LAMemberShipForm: LAMemberShipForm.form[0].link})
        });

    }

}

export default App;
