import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyAAuHXgvXhkoq0SoPXWaQCwKtUmC0TFyP8",
    authDomain: "assct-journals.firebaseapp.com",
    databaseURL: "https://assct-journals.firebaseio.com",
    projectId: "assct-journals",
    storageBucket: "assct-journals.appspot.com",
    messagingSenderId: "1053479031171",
    appId: "1:1053479031171:web:f56db4b6653707577666b6"
}, "Journals");
const JournalDB = firebaseApp.firestore();
export {JournalDB};





