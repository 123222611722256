import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyCNPZt56h5rO0ixk6--C2EjXjV9V5iGWNY",
    authDomain: "louisianadivisionassct.firebaseapp.com",
    databaseURL: "https://louisianadivisionassct.firebaseio.com",
    projectId: "louisianadivisionassct",
    storageBucket: "louisianadivisionassct.appspot.com",
    messagingSenderId: "432498453556",
    appId: "1:432498453556:web:3e4eafd27dbb682fe96e61"
}, "LADivision");
const LAdb = firebaseApp.firestore();
export {LAdb};





