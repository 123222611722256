import React from 'react';

import '../Component.css';
import './Divisions.css'
import {LAdb} from "../../config/LAConf";
import {NavLink} from 'react-router-dom'

class LADivision extends React.Component {

    selectedSection = "HomePage";

    constructor(props) {
        super(props);
        this.state = {
            historyOfLouisiana: "",
            render: false
        };
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let urlParams = new URLSearchParams(window.location.search).toString();
        let destinationPage;

        if (urlParams === "destination=annualMeetingPage") {
            destinationPage = "LouisianaAnnualMeeting";
        } else {
            destinationPage = "HomePage"
        }


        if (document.getElementById(destinationPage) !== null && document.getElementById(destinationPage) !== undefined) {
            this.updateSelectedSection(destinationPage);
        }
    }

    async loadData() {
        let pageHeaders = [];
        let statePushObject = {};
        let historyOfLouisiana = {};

        LAdb.collection("History Of Louisiana").get().then(snapshot => {
            snapshot.forEach(doc => {
                historyOfLouisiana = doc.data();

            })
        });

        LAdb.collection("LADivisionPage").get().then(snapshot => {
            snapshot.forEach(doc => {
                if (!(doc.id in pageHeaders) && (doc.id !== "Fellowship -Scholarships -Professorship &  Science Fair Awards Page")) {
                    pageHeaders.push(doc.id)
                }
                let sectionId = doc.id;

                statePushObject[sectionId] = doc.data();

            });
            let first = "HomePage";
            pageHeaders.sort(function (x, y) {
                return x === first ? -1 : y === first ? 1 : 0;
            });
            this.setState({divisionInfo: statePushObject});
            this.setState({pageHeaders: pageHeaders});
            this.setState({
                historyOfLouisiana: historyOfLouisiana,
                render: true
            });

        });

    }

    render() {

        if (!this.state.render) {
            return (
                <>
                    Loading...
                </>
            )
        }

        if (window.screen.width >= 992) {
            return (
                <div className="App-Component">
                    <div className={"divisionsPage"}>

                        <div className={"row"}>
                            <div className={"col-4"}/>

                            <div className={"col-4"}>
                                <h2 className={"PageHeader"}>
                                    Louisiana Division
                                </h2>
                            </div>
                            <div className={"donationButton col"}>
                                <button className={"btn btn-warning"}>
                                    <NavLink exact to={"/donate"}> Pay or Donate</NavLink>
                                </button>
                            </div>
                        </div>

                        <div className={"row"}>
                            <table className="table table-bordered table-responsive-md">
                                <tbody>
                                <tr>
                                    {
                                        this.state.pageHeaders.map(header => {
                                            if (header === "History of the Louisiana Sugar Industry") {
                                                return (
                                                    <td id={header.toString().replace(/ /g, "")} key={header}
                                                        className={"LAPageHeader"}>
                                                        <a href={this.state.historyOfLouisiana.link} target={"_blank"}>

                                                            <h5>
                                                                {header}
                                                            </h5>
                                                        </a>

                                                    </td>
                                                )
                                            } else {
                                                return (

                                                    <td id={header.toString().replace(/ /g, "")} key={header}
                                                        onClick={() => {
                                                            this.updateSelectedSection(header.toString().replace(/ /g, ""))
                                                        }} className={"LAPageHeader"}>
                                                        <h5>
                                                            {header}
                                                        </h5>
                                                    </td>

                                                )
                                            }

                                        })}
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="pageBodySection">
                            <div id={"HomePageSection"} className={"d-none"}>


                                {
                                    this.state.divisionInfo["HomePage"]["Home Page Links"].map(link => {
                                        return (
                                            Object.keys(link).map(key => {

                                                return (
                                                    <>
                                                        <h6>
                                                            <a href={link[key]} target={"_blank"}>- {key}</a>
                                                        </h6>
                                                    </>
                                                )

                                            })
                                        )


                                    })}
                            </div>
                            <div id={"LouisianaAnnualMeetingSection"} className={"d-none"}>
                                <>
                                    <b>
                                        <u><h4>Next Louisiana Division Meeting</h4></u>
                                    </b>
                                    <h6>
                                        {this.state.divisionInfo["Louisiana Annual Meeting"]["Next Meeting"]["Date"]}
                                    </h6>
                                    <h6>
                                        {this.state.divisionInfo["Louisiana Annual Meeting"]["Next Meeting"]["Venue"]}
                                    </h6>
                                    <h6>
                                        {this.state.divisionInfo["Louisiana Annual Meeting"]["Next Meeting"]["Location"]}
                                    </h6>

                                    <div className={"row LAHotelInfo"}>
                                        <div className={"col-2"}>
                                            <h5>
                                                <u>Members</u>
                                            </h5>
                                            {this.state.divisionInfo["Louisiana Annual Meeting"]["Members Links"].map(link => {
                                                return (
                                                    <>
                                                        <h6>
                                                            <a href={link["Link"]} target={"_blank"}> {link["Text"]}</a>
                                                        </h6>
                                                    </>
                                                )
                                            })}
                                        </div>
                                        <div className={"col-7"}>
                                            <h5>
                                                <u>Hotel Reservations</u>
                                            </h5>
                                            {this.state.divisionInfo["Louisiana Annual Meeting"]["Hotel Reservation Text"].map(text => {
                                                return (
                                                    <>
                                                        <blockquote>
                                                            {text}
                                                        </blockquote>
                                                    </>
                                                )
                                            })}
                                            Conference rates are as follows:
                                            <table className={"table"}>
                                                <thead>
                                                <tr>
                                                    <td><b>
                                                        Room
                                                    </b></td>
                                                    <td><b>Single Rate</b></td>
                                                </tr>
                                                </thead>
                                                {this.state.divisionInfo["Louisiana Annual Meeting"]["Hotel Rates"].map(room => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                {room["Room"]}
                                                            </td>
                                                            <td>
                                                                {room["Price"]}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </table>


                                        </div>
                                        <div className={"col-3"}>
                                            <h5>
                                                <u>Exhibitors</u>
                                            </h5>

                                            {this.state.divisionInfo["Louisiana Annual Meeting"]["Exhibitors"].map(exhibitor => {

                                                return (
                                                    <>
                                                        <h6>
                                                            <a href={exhibitor["Link"]}
                                                               target={"_blank"}>- {exhibitor["Text"]}</a>
                                                        </h6>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </>
                            </div>

                            <div id={"Fellowship-Scholarships-Professorship&ScienceFairAwardsPageSection"}
                                 className={"d-none"}>
                                <h5>
                                    <u>Click on the links below for the following information:</u>
                                </h5>
                                {this.state.divisionInfo["Fellowship -Scholarships -Professorship &  Science Fair Awards Page"]["Links"].map(link => {
                                    return (
                                        <>
                                            <h6>
                                                <a href={link[["Link"]]} target={"_blank"}>{link["Text"]}</a><br/>
                                            </h6>
                                        </>
                                    )

                                })
                                }

                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="App-Component">
                    <div className={"divisionsPage"}>


                        <div className={"row"}>
                            <div className={"col-4"}/>

                            <div className={"col-4"}>
                                <h2 className={"PageHeader"}>
                                    Louisiana Division
                                </h2>
                            </div>
                            <div className={"donationButton col-4"}>
                                <button className={"btn btn-warning"}>
                                    <NavLink exact to={"/donate"}> Pay or Donate</NavLink>
                                </button>
                            </div>
                        </div>

                        <div className={"row"}>
                            <table className="table table-bordered table-responsive-md">
                                <tbody>
                                <tr>
                                    {
                                        this.state.pageHeaders.map(header => {
                                            if (header === "History of the Louisiana Sugar Industry") {
                                                return (
                                                    <td id={header.toString().replace(/ /g, "")} key={header}
                                                        className={"LAPageHeader"}>
                                                        <a href={this.state.historyOfLouisiana.link} target={"_blank"}>

                                                            <h5>
                                                                {header}
                                                            </h5>
                                                        </a>

                                                    </td>
                                                )
                                            } else {
                                                return (

                                                    <td id={header.toString().replace(/ /g, "")} key={header}
                                                        onClick={() => {
                                                            this.updateSelectedSection(header.toString().replace(/ /g, ""))
                                                        }} className={"LAPageHeader"}>
                                                        <h5>
                                                            {header}
                                                        </h5>
                                                    </td>

                                                )
                                            }

                                        })}
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div id={"HomePageSection"} className={"d-none"}>

                            {
                                this.state.divisionInfo["HomePage"]["Home Page Links"].map(link => {
                                    return (
                                        Object.keys(link).map(key => {

                                            return (
                                                <>
                                                    <h6>
                                                        <a href={link[key]} target={"_blank"}>- {key}</a>
                                                    </h6>
                                                </>
                                            )

                                        })
                                    )


                                })}
                        </div>
                        <div id={"LouisianaAnnualMeetingSection"} className={"d-none"}>
                            <>
                                <b>
                                    <u><h4>Next Louisiana Division Meeting</h4></u>
                                </b>
                                <h6>
                                    {this.state.divisionInfo["Louisiana Annual Meeting"]["Next Meeting"]["Date"]}
                                </h6>
                                <h6>
                                    {this.state.divisionInfo["Louisiana Annual Meeting"]["Next Meeting"]["Venue"]}
                                </h6>
                                <h6>
                                    {this.state.divisionInfo["Louisiana Annual Meeting"]["Next Meeting"]["Location"]}
                                </h6>

                                <div className={"row"}>
                                    <div className={"col-12 LAHotelInfo"}>
                                        <h5>
                                            <u>Hotel Reservations</u>
                                        </h5>
                                        {this.state.divisionInfo["Louisiana Annual Meeting"]["Hotel Reservation Text"].map(text => {
                                            return (
                                                <>
                                                    <blockquote>
                                                        {text}
                                                    </blockquote>
                                                </>
                                            )
                                        })}
                                        Conference rates are as follows:
                                        <table className={"table"}>
                                            <thead>
                                            <tr>
                                                <td><b>
                                                    Room
                                                </b></td>
                                                <td><b>Single Rate</b></td>
                                            </tr>
                                            </thead>
                                            {this.state.divisionInfo["Louisiana Annual Meeting"]["Hotel Rates"].map(room => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            {room["Room"]}
                                                        </td>
                                                        <td>
                                                            {room["Price"]}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </table>


                                    </div>
                                    <div className={"col-5"}>
                                        <h5>
                                            <u>Members</u>
                                        </h5>
                                        {this.state.divisionInfo["Louisiana Annual Meeting"]["Members Links"].map(link => {
                                            return (
                                                <>
                                                    <h6>
                                                        <a href={link["Link"]} target={"_blank"}> {link["Text"]}</a>
                                                    </h6>
                                                </>
                                            )
                                        })}
                                    </div>
                                    <div className={"col-5"}>
                                        <h5>
                                            <u>Exhibitors</u>
                                        </h5>

                                        {this.state.divisionInfo["Louisiana Annual Meeting"]["Exhibitors"].map(exhibitor => {

                                            return (
                                                <>
                                                    <h6>
                                                        <a href={exhibitor["Link"]}
                                                           target={"_blank"}>- {exhibitor["Text"]}</a>
                                                    </h6>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            </>
                        </div>

                        <div id={"Fellowship-Scholarships-Professorship&ScienceFairAwardsPageSection"}
                             className={"d-none"}>
                            <h5>
                                <u>Click on the links below for the following information:</u>
                            </h5>
                            {this.state.divisionInfo["Fellowship -Scholarships -Professorship &  Science Fair Awards Page"]["Links"].map(link => {
                                return (
                                    <>
                                        <h6>
                                            <a href={link[["Link"]]} target={"_blank"}>{link["Text"]}</a><br/>
                                        </h6>
                                    </>
                                )

                            })
                            }


                        </div>
                    </div>
                </div>
            );
        }


    }

    updateSelectedSection(ID) {
        console.debug("update called from: " + ID);
        document.getElementById(this.selectedSection).classList.remove("selected");
        document.getElementById(ID).classList.add("selected");
        document.getElementById(this.selectedSection + "Section").classList.add("d-none");
        document.getElementById(ID + "Section").classList.remove("d-none");

        this.selectedSection = ID;
    }

}


export default LADivision;
