import React from 'react';


import '../Component.css';
import './Journals.css'
import volunteerForm from "../../resources/PDFs/Reviewer volunteer form.pdf"
import guidelines from "../../resources/PDFs/Editorial Policy.pdf"
import {MyContext} from "../../config/Theme-Context";

class Journals extends React.Component {

    selectedYear = new Date().getFullYear().toString();
    journals = {};
    selectedVolume = this.selectedYear - 1980;

    journals_existence = {};

    render() {

        let currentYear = this.selectedYear;
        let startYear = 2004;
        for (startYear; startYear <= currentYear; startYear++) {
            this.journals[startYear] = startYear - 1980;
            this.journals_existence[startYear] = false;
        }


        return (

            <div className="App-Component">

                <div id="Journals">
                    <div>
                        <h2 className={"PageHeader"}>Journal of the American Society of Sugar Cane Technologists
                            <br/>
                        </h2>
                        <h6 id="JournalSubHeadLine">
                            The Journal of the American Society of Sugar Cane Technologists (JASSCT) is an electronic
                            journal that includes both refereed and non-refereed articles. Titles and abstracts from
                            refereed papers published in the journal (1997 and later) are listed below. Articles can be
                            downloaded or printed free-of-charge. Printed copies of the journal and reprints of articles
                            are
                            no longer available. The Proceedings of the International Society of Sugar Cane
                            Technologists
                            can be accessed through the ISSCT.org website.

                        </h6>

                        <div id="onlineIssueSection">
                            <h5>
                                On-Line Issues <br/>
                            </h5>

                            <h6>
                                Click on a volume to view contents and access articles
                            </h6>
                            <div className={"row onlineIssues"}>
                                <MyContext.Consumer>
                                    {({journals}) => {
                                        if (journals === false) {
                                            return (
                                                <>
                                                    Loading...
                                                </>
                                            )
                                        } else {
                                            return (
                                                <div className={"col-3 journalList"}>
                                                    {
                                                        Object.keys(this.journals).sort((a, b) => b - a).map(year => {
                                                            let vol = year - 1980;
                                                            if (journals["Volume " + vol]){
                                                                let current = journals["Volume " + vol][0];
                                                                if (Object.keys(current).includes("PEER REFEREED JOURNAL ARTICLES") ||
                                                                    Object.keys(current).includes("PEER REFEREED JOURNAL ARTICLE")) {
                                                                    this.journals_existence[year] = true;
                                                                    return (
                                                                        <span onClick={() => {
                                                                            this.updateSelectedJournal(year, this.journals[year])
                                                                        }}>
                                                                            <h5 id={year} className={"volume"}>
                                                                                <hr/>
                                                                                {year.toString() + ': Vol ' + this.journals[year]}
                                                                                <hr/>
                                                                            </h5>
                                                                        </span>)
                                                                }
                                                            }

                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                    }}
                                </MyContext.Consumer>

                                <MyContext.Consumer>
                                    {({journals}) => {
                                        if (journals === false) {
                                            return (
                                                <>
                                                </>
                                            )
                                        } else {

                                            while (!this.journals_existence[this.selectedYear]) {
                                                this.selectedYear = this.selectedYear - 1;
                                                this.selectedVolume = this.selectedVolume - 1;
                                            }

                                            return (
                                                <div className={"col-9"} id={"volumeSection"}>
                                                    <div id="selectedJournal">
                                                        Click on an article to view its content
                                                        <h3 id="selectedJournalBody">
                                                            <u>Volume <span
                                                                className="selectedJournalVolume">{this.selectedVolume}</span>, <span
                                                                className="selectedJournalYear">{this.selectedYear}</span></u>
                                                        </h3>

                                                        {this.checkForNullPresidentArticle(journals["Volume " + this.selectedVolume][0], "LA President Message")}

                                                        {this.checkForNullPresidentArticle(journals["Volume " + this.selectedVolume][0], "FL President Message")}

                                                        {this.returnJournalArticles(journals["Volume " + this.selectedVolume][0])}

                                                        {this.returnMemoriamMembers(journals["Volume " + this.selectedVolume][0])}

                                                    </div>
                                                </div>
                                            )

                                        }
                                    }
                                    }
                                </MyContext.Consumer>
                            </div>
                        </div>

                    </div>


                    <MyContext.Consumer>
                        {({journalContact}) => {
                            if (journalContact === false) {
                                return ""
                            } else {
                                let data = journalContact.ContactInfo[0];
                                return (
                                    <div className={"ContactInfo"}>
                                        <h6><u>Contact information for the ASSCT Journal editor:</u></h6>
                                        <h6>
                                            {data.ContactName}<br/>
                                            <i>{data.LocationName}<br/>
                                                {data.Address}<br/>
                                                {data.CityAndState}</i><br/>
                                            <a href={"mailto:" + data.Email}>{data.Email}</a>
                                        </h6>
                                    </div>
                                )

                            }
                        }
                        }
                    </MyContext.Consumer>


                    <div className={"row"}>
                        <div className={"col-2"}/>
                        <div className={"col-8"}>
                            <h6>
                                <a href={"https://louisianadigitallibrary.org/islandora/object/lsu-ag-assct:collection"} target={"_blank"}>
				    Click here to access digitized copies of the Journal of the ASSCT from 1941 through 2003
                                </a>
                            </h6>
                            <h6>
                                <a href={guidelines} target={"_blank"}>

                                    Click here for editorial guidelines for papers to be published in the Journal of
                                    ASSCT
                                </a>
                            </h6>
                            <h6>
                                <a href={volunteerForm} target={"_blank"}>

                                    Click here to volunteer to serve as reviewer for the Journal of the ASSCT
                                </a>
                            </h6>
                            <h6>
                                <a href={"http://www.issct.org/proceedings.html"} target={"_blank"}>
                                    Click here for the Proceedings of the International Society of Sugar Cane
                                    Technologists
                                </a>
                            </h6>
                        </div>
                        <div className={"col-2"}/>
                    </div>


                    <h6 id="journalNote">
                        Note: The 2002-2003 Joint ASSCT Executive Committee voted at their June 2003 business
                        meeting to
                        convert to an entirely electronic journal beginning with Volume 24. Hard copies of journal
                        volumes are not available. Electronic journal articles may be downloaded or printed
                        free-of-charge.
                    </h6>
                </div>

            </div>
        );

    }


    returnMemoriamMembers(volume) {
        if (volume["In  Memoriam"] !== undefined) {
            return (
                <h6>
                    <u>
                        In Memoriam
                    </u><br/>
                    {volume["In  Memoriam"].map(member => {
                        return (
                            <>
                                <a href={member["Link"]} target={"_blank"}>
                                    {member["Name"]}
                                </a>
                            </>
                        )
                    })}
                </h6>
            )
        }
    }

    returnJournalArticles(volume) {
        if (volume["PEER REFEREED JOURNAL ARTICLES"] !== undefined) {

            return (
                <div>
                    <h6><u>PEER REFEREED JOURNAL ARTICLES</u></h6>
                    {volume["PEER REFEREED JOURNAL ARTICLES"].sort(function (a, b) {
                        let aPage = parseInt(a["Page Number"].split("-")[0]);
                        let bPage = parseInt(b["Page Number"].split("-")[0]);

                        if (aPage > bPage) {
                            return 1
                        } else {
                            return -1
                        }
                    }).map(article => {
                        return (
                            <>
                                <h6 key={article}>
                                    <a href={article["Link"]} target={"_blank"}>
                                        {article["Title"]} Pg. {article["Page Number"]}<br/>
                                        {this.returnAuthor(article)}
                                    </a>

                                </h6>
                            </>
                        )

                    })
                    }

                </div>
            )
        }

        if (volume["PEER REFEREED JOURNAL ARTICLE"] !== undefined) {

            return (
                <div>
                    <h6><u>PEER REFEREED JOURNAL ARTICLES</u></h6>
                    {volume["PEER REFEREED JOURNAL ARTICLE"].sort(function (a, b) {
                        let aPage = parseInt(a["Page Number"].split("-")[0]);
                        let bPage = parseInt(b["Page Number"].split("-")[0]);

                        if (aPage > bPage) {
                            return 1
                        } else {
                            return -1
                        }
                    }).map(article => {
                        return (
                            <>
                                <h6 key={article}>
                                    <a href={article["Link"]} target={"_blank"}>
                                        {article["Title"]} Pg. {article["Page Number"]}<br/>
                                        {this.returnAuthor(article)}
                                    </a>

                                </h6>
                            </>
                        )

                    })
                    }

                </div>
            )
        }


    }

    checkForNullPresidentArticle(volume, presidentMessage) {
        if (volume["President_Articles"] !== undefined && volume["President_Articles"] !== null && volume["President_Articles"][presidentMessage] !== undefined) {
            if(presidentMessage === "FL President Message") {
                return (
                    <h5>
                        <a href={volume["President_Articles"][presidentMessage]}
                           target={"_blank"}>- <span
                            className={"selectedJournalYear"}>{this.selectedYear}</span> President's
                            message
                            Florida
                            Division
                        </a>
                    </h5>
                )
            }
            if(presidentMessage === "LA President Message") {
                return (
                    <h5>
                        <a href={volume["President_Articles"][presidentMessage]}
                           target={"_blank"}>- <span
                            className={"selectedJournalYear"}>{this.selectedYear}</span> President's
                            message
                            Louisiana
                            Division
                        </a>
                    </h5>
                )
            }

        }

    }

    returnAuthor(article) {
        if ((article["Author"] !== undefined) && (article["Author"] !== "")) {
            return (
                <>
                    <i>- {article["Author"]}</i>
                </>
            )
        }
    }

    updateSelectedJournal(year, volume) {
        console.debug("Updated selection\n Year: " + year + " Volume: " + volume);
        let volElements = document.getElementsByClassName("selectedJournalVolume");
        let yearElements = document.getElementsByClassName("selectedJournalYear");
        for (let i = 0; i < volElements.length; i++) {
            volElements[i].innerHTML = this.journals[year]
        }

        for (let i = 0; i < yearElements.length; i++) {
            yearElements[i].innerHTML = year
        }

        this.selectedVolume = volume;
        if (document.getElementById(this.selectedYear.toString()) !== null)
            document.getElementById(this.selectedYear.toString()).style.color = "black";
        document.getElementById(year).style.color = "green";
        this.selectedYear = year;
        this.forceUpdate();

    }
}


export default Journals;
