import React from 'react';

import '../Component.css';
import "./Donations.css"

class DonationComponent extends React.Component {
    donateLink = "https://www.paypal.com/donate/?token=pos3LtAO0AZdeSo8JCtmE0xTUIQeww0fOmipYdnHXKjkkteSQpvr4vfQxOy71Nb_jGxQH0&country.x=US&locale.x=US"

    render() {
        if (window.screen.width <= 991) {
            return this.generatePage(true)

        } else {
            return this.generatePage(false)
        }
    }


    generatePage(displayMobile) {

        if (displayMobile) {
            return (
                <div className="App-Component">
                    <div id={"DonationPage"}>
                        <h2 className={"PageHeader"}>
                            Payments and Donations to the<br/>
                            American Society of Sugar Cane Technologist
                        </h2>

                        <blockquote>
                            Activities of the American Society of Sugar Cane Technologists are funded through membership
                            dues,
                            meeting registrations, and donations. Membership dues and meeting registration fees can be
                            paid,
                            and
                            donations can be made by check or through PAYPAL. Those without a PAYPAL account can pay as
                            a
                            guest. Membership and/or registration forms should be sent to the respective Florida or
                            Louisiana
                            division. Individuals can pay by check to their respective division. <b>If PAYPAL is used,
                            please
                            note
                            the
                            purpose of your payment/donation in reference line.</b>
                        </blockquote>
                        <h6>
                            Below are links to PAYPAL payment/donations:
                        </h6><br/>


                        <div className={"d-none"}>
                            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top"><input
                                type="hidden" name="cmd" value="_s-xclick"/>
                                <input type="hidden" name="hosted_button_id" value="FRT3XFG672Q2E"/>
                                <input id={"JTDonation"} type="image"
                                       src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
                                       name="submit" title="PayPal - The safer, easier way to pay online!"
                                       alt="Donate with PayPal button" formTarget={"_blank"}/>
                            </form>
                        </div>

                        <div className={"d-none"}>
                            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                                <input type="hidden" name="cmd" value="_s-xclick"/>
                                <input type="hidden" name="hosted_button_id" value="JRBWXBD2528YC"/>
                                <input id={"FLDonation"} type="image"
                                       src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
                                       name="submit" title="PayPal - The safer, easier way to pay online!"
                                       alt="Donate with PayPal button" formTarget={"_blank"}/>

                                <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1"
                                     height="1" />
                            </form>
                        </div>
                        
                        <div className={"d-none"}>
                            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top"><input
                                type="hidden" name="cmd" value="_s-xclick"/>
                                <input type="hidden" name="hosted_button_id" value="K8E99Z7UPMTNU" />
                                <input id={"LADonation"} type="image"
                                       src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
                                       name="submit" title="PayPal - The safer, easier way to pay online!"
                                       alt="Donate with PayPal button" formTarget={"_blank"}/>
                            </form>
                        </div>                        

                        <div className={"row"}>
                            <div className={"col"}>
                                <h6>
                                    To pay/donate to the
                                    JOINT Society use this link
                                </h6>
                                <button className={"btn btn-warning"} onClick={this.openJTDonationPage}>
                                    Donate to Joint Society
                                </button>
                            </div>
                            <div className={"col"}>
                                <h6>
                                    To pay/donate to the
                                    FLORIDA Division use this link
                                </h6>
                                <button className={"btn btn-warning"} onClick={this.openFLDonationPage}>
                                    Donate to Florida Division
                                </button>
                            </div>
                            <div className={"col"}>
                                <h6>
                                    To pay/donate to the
                                    LOUISIANA Division use this link
                                </h6>
                                <button className={"btn btn-warning"} onClick={this.openLADonationPage}>
                                    Donate to Louisiana Division
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="App-Component">
                    <div id={"DonationPage"}>
                        <h2 className={"PageHeader"}>
                            Payments and Donations to the<br/>
                            American Society of Sugar Cane Technologist
                        </h2>

                        <div className={"d-none"}>
                            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top"><input
                                type="hidden" name="cmd" value="_s-xclick"/>
                                <input type="hidden" name="hosted_button_id" value="FRT3XFG672Q2E"/>
                                <input id={"JTDonation"} type="image"
                                       src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
                                       name="submit" title="PayPal - The safer, easier way to pay online!"
                                       alt="Donate with PayPal button" formTarget={"_blank"}/>
                            </form>
                        </div>

                        <div className={"d-none"}>
                            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">

                                <input type="hidden" name="cmd" value="_s-xclick"/>

                                <input type="hidden" name="hosted_button_id" value="JRBWXBD2528YC"/>

                                <input id={"FLDonation"} type="image"
                                       src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
                                       name="submit" title="PayPal - The safer, easier way to pay online!"
                                       alt="Donate with PayPal button" formTarget={"_blank"}/>

                                <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1"
                                     height="1"/>

                            </form>
                        </div>
                        
                                                <div className={"d-none"}>
                            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top"><input
                                type="hidden" name="cmd" value="_s-xclick"/>
                                <input type="hidden" name="hosted_button_id" value="K8E99Z7UPMTNU" />
                                <input id={"LADonation"} type="image"
                                       src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
                                       name="submit" title="PayPal - The safer, easier way to pay online!"
                                       alt="Donate with PayPal button" formTarget={"_blank"}/>
                            </form>
                        </div>

                        <blockquote>
                            Activities of the American Society of Sugar Cane Technologists are funded through membership
                            dues,
                            meeting registrations, and donations. Membership dues and meeting registration fees can be
                            paid,
                            and
                            donations can be made by check or through PAYPAL. Those without a PAYPAL account can pay as
                            a
                            guest. Membership and/or registration forms should be sent to the respective Florida or
                            Louisiana
                            division. Individuals can pay by check to their respective division. <b>If PAYPAL is used,
                            please
                            note
                            the
                            purpose of your payment/donation in the reference line.</b>
                        </blockquote>
                        <h6>
                            Below are links to PAYPAL payment/donations:
                        </h6>

                        <div className={"row"}>
                            <div className={"col-4"}>
                                <h6>
                                    To pay/donate to the
                                    JOINT Society use this link
                                </h6>
                                <button className={"btn btn-warning"} onClick={this.openJTDonationPage}>
                                    Donate to Joint Society
                                </button>
                            </div>
                            <div className={"col-4"}>
                                <h6>
                                    To pay/donate to the
                                    FLORIDA Division use this link
                                </h6>
                                <button className={"btn btn-warning"} onClick={this.openFLDonationPage}>
                                    Donate to Florida Division
                                </button>
                            </div>
                            <div className={"col-4"}>
                                <h6>
                                    To pay/donate to the
                                    LOUISIANA Society use this link
                                </h6>
                                <button className={"btn btn-warning"} onClick={this.openLADonationPage}>
                                    Donate to Louisiana Division
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

    }


    componentDidMount() {
    }

    openJTDonationPage() {
        document.getElementById("JTDonation").click()
    }

    openFLDonationPage() {
        document.getElementById("FLDonation").click()
    }

    openLADonationPage() {
        document.getElementById("LADonation").click()
    }

}

export default DonationComponent;
