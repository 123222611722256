import React from 'react';
import '../Component.css';
import "./Meetings.css";
import {MyContext} from "../../config/Theme-Context";
import "./JointMeetings.css"

class JointMeetings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        return (

            <div className="App-Component">
                <div id={"MeetingsPage"}>
                    <MyContext.Consumer>

                        {({meetings}) => {
                            if (meetings === false) {
                                return (<>Loading...</>)
                            } else {

                                let annualInfo = meetings["AnnualJointMeetingInfo"];

                                return (
                                    <>
                                        

					<h2 className={"PageHeader"} id={"JointMeetingsHeader"}>
                                            American Society of Sugar Cane Technologists<br/>
					
                                            {annualInfo["General_Information"]["Number"]} Annual Joint Meeting
                                        </h2>


                                        <h5 className={"PageHeader"} id="JoinSubHeader">
                                            {annualInfo["General_Information"]["Venue"]}<br/>
                                            {annualInfo["General_Information"]["Date"]}
                                        </h5>
                                        <div className={"row"}>
                                            <div className={"col-3"}>
                                                <h5>
                                                    <u>Members' Information</u>
                                                </h5>

                                                {annualInfo["Members_Information"].sort
                                                ((a, b) => {
                                                    return a.Column_Order - b.Column_Order
                                                }).map(exhibitorInfo => {

                                                    return (
                                                        <>
                                                            <h6>
                                                                <a href={exhibitorInfo.Link}
                                                                   target={"_blank"}> {exhibitorInfo.Text}</a>
                                                            </h6>
                                                        </>
                                                    )

                                                })}
                                            </div>
                                            <div className={"col-6"}>
                                                <center>
                                                    <h5>
                                                        <u>{annualInfo["Venue_Information"]["Venue"]}</u>
                                                    </h5>

                                                    {annualInfo["Venue_Information"]["Event_Information"].sort
                                                    ((a, b) => {
                                                        return a.Column_Order - b.Column_Order
                                                    }).map(venueInfo => {

                                                        return (
                                                            <>
                                                                <h6>
                                                                    <a href={venueInfo.Link}
                                                                       target={"_blank"}> {venueInfo.Text}</a>
                                                                </h6>
                                                            </>
                                                        )

                                                    })}
                                                </center>


                                            </div>
                                            <div className={"col"}>
                                                <h5>
                                                    <u>Exhibitor's Information</u>
                                                </h5>

                                                {annualInfo["Exhibitor_Information"].sort
                                                ((a, b) => {
                                                    return a.Column_Order - b.Column_Order
                                                }).map(exhibitorInfo => {

                                                    return (
                                                        <>
                                                            <h6>
                                                                <a href={exhibitorInfo.Link}
                                                                   target={"_blank"}> {exhibitorInfo.Text}</a>
                                                            </h6>
                                                        </>
                                                    )

                                                })}
                                            </div>
                                        </div>

                                    </>
                                )
                            }
                        }}

                    </MyContext.Consumer>
                </div>
            </div> 
        )

    }

}

export default JointMeetings;
