import React from 'react';
import '../Component.css';
import "./Meetings.css";
import {MyContext} from "../../config/Theme-Context";
import {NavLink} from 'react-router-dom'

class Meetings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        return (

            <div className="App-Component">
                <div id={"MeetingsPage"}>

                    <div className={"row"}>
                        <div className={"col-4"}>
                        </div>
                        <div className={"col-4"}>
                            <h2 className={"PageHeader"}>
                                ASSCT Meetings
                            </h2>
                        </div>
                        <div className={"donationButton col"}>
                            <button className={"btn btn-warning donateButton"}>
                                <NavLink exact to={"/donate"}> Pay or Donate</NavLink>
                            </button>
                        </div>
                    </div>
                    <MyContext.Consumer>

                        {({meetings}) => {
                            if (meetings === false) {
                                return (<>Loading...</>)
                            } else {

                                let floridaMeetings = meetings["Florida_Division_Meetings"]["meetings"];
                                let futureISSCTMeetings = meetings["Future_ISSCT_Congress"]["meetings"];
                                let jointDivisionMeetings = meetings["Joint_Division_Meetings"]["meetings"];
                                let louisianaMeetings = meetings["Louisiana_Division_Meetings"]["meetings"];

                                let allMeetings = [floridaMeetings, futureISSCTMeetings, louisianaMeetings, jointDivisionMeetings];
                                let numberOfRows = 0;

                                allMeetings.forEach(meetings => {
                                    if (meetings.length > numberOfRows) {
                                        numberOfRows = meetings.length;
                                    }
                                });

                                let meetingsIndexes = [];
                                for (let i = 0; i < numberOfRows; i++) {
                                    meetingsIndexes.push(i)
                                }

                                return (

                                    <>
                                        <table className="table table-striped table-bordered table-responsive-md">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <h5>
                                                    <NavLink exact to={"/JointMeetings"}>
                                                            Joint
                                                   </NavLink>
                                                    </h5>
                                                </td>

                                                <td>
                                                    <h5>
                                                        <NavLink exact to={"/FLDivision?destination=annualMeetingPage"}>
                                                            Florida
                                                        </NavLink>
                                                    </h5>
                                                </td>

                                                <td>
                                                    <h5>
                                                        <NavLink exact to={"/LADivision?destination=annualMeetingPage"}>
                                                            Louisiana
                                                        </NavLink>
                                                    </h5>
                                                </td>

                                                <td>
                                                    <h5>
                                                        <a href={"http://www.issct.org/"} target={"_blank"}>
                                                            ISSCT
                                                        </a>
                                                    </h5>
                                                </td>
                                            </tr>
                                            {
                                                meetingsIndexes.map(meetingsIndex => {
                                                    return (
                                                        <tr>
                                                            <td>{this.checkNullIndex(jointDivisionMeetings, meetingsIndex)}
                                                            </td>

                                                            <td>{this.checkNullIndex(floridaMeetings, meetingsIndex)}
                                                            </td>

                                                            <td>{this.checkNullIndex(louisianaMeetings, meetingsIndex)}
                                                            </td>

                                                            <td>{this.checkNullIndex(futureISSCTMeetings, meetingsIndex)}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </table>

                                    </>
                                )

                            }
                        }}

                    </MyContext.Consumer>

                </div>
            </div>
        );

    }

    checkNullIndex(division, index) {
        if (division[index] !== undefined && division[index] !== null) {
            let keys = ["Date", "Venue", "City"];
            return (
                <h6 className={"meeting"}>
                    {
                        keys.map(key => {
                            console.debug("KEY: " + key)
                            return (
                                <>
                                    {division[index][key]}<br/>
                                </>
                            )
                        })
                    }
                </h6>
            )
        }
    }

}


export default Meetings;
