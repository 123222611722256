import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyCjqZV3PY6WnV3qOqyeA30RH_VNU58a-rI",
    authDomain: "louisianadivsionassct.firebaseapp.com",
    databaseURL: "https://louisianadivsionassct.firebaseio.com",
    projectId: "louisianadivsionassct",
    storageBucket: "louisianadivsionassct.appspot.com",
    messagingSenderId: "484138282150",
    appId: "1:484138282150:web:180573690776c9e2359867"
}, "FLDivision");
// Initialize Firebase
const FLdb = firebaseApp.firestore();
export {FLdb};