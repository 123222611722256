import React from 'react';
import {NavLink} from 'react-router-dom'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import '../App.css';
import './Header.css'

import logo from '../../resources/images/assct_logo.png'
import {MyContext} from "../../config/Theme-Context";

class Header extends React.Component {


    render() {
        let mainMenu = 'mainMenu';
        let mainMenuTargetable = '#' + mainMenu;

        return (
            <div id={'mainHeader'}>

                <nav className="navbar navbar-expand-lg navbar-light">

                    <div className="centerLogo">
                        <NavLink className="navbar-brand" exact to="/">
                            <img id="logo" src={logo} alt="ASSCT Logo"/>
                        </NavLink>
                    </div>

                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target={mainMenuTargetable}
                            aria-controls={mainMenu} aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"/>
                    </button>

                    {generateNavBar(mainMenu, mainMenuTargetable)}
                </nav>
            </div>

        );
    }

    componentDidMount() {
        document.getElementById("dropdown-basic-button").classList.remove("btn", "btn-primary");

        document.getElementById("dropdown-basic-button1").classList.remove("btn", "btn-primary");
    }

}

function generateNavBar(id, idTarget) {
    if (window.screen.width >= 992) {
        return (
            <div className="collapse navbar-collapse" id={id}>
                <div className="navbar-nav" id='main-menu'>
                    <NavLink className="nav-item nav-link" exact to="/">
                        <span data-toggle="collapse">Home</span>
                    </NavLink>
                    <NavLink className="nav-item nav-link" exact to="/journals">
                        <span data-toggle="collapse">Journals</span>
                    </NavLink>
                    <NavLink className="nav-item nav-link" exact to="/officers">
                        <span data-toggle="collapse">Officers</span>
                    </NavLink>
                    <NavLink className="nav-item nav-link" exact to="/meetings">
                        <span data-toggle="collapse">Meetings</span>
                    </NavLink>
                    <NavLink className="nav-item nav-link" exact to="/membership">
                        <span data-toggle="collapse">Membership</span>
                    </NavLink>
                    <NavLink className="nav-item nav-link" exact to="/honoraryMembers">
                        <span data-toggle="collapse">Honorary Members</span>
                    </NavLink>
                    <DropdownButton id="dropdown-basic-button" title="Divisions" className="nav-item nav-link">
                        <Dropdown.Item>
                            <NavLink exact to="/FLDivision">Florida Division</NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <NavLink exact to="/LADivision">Louisiana Division</NavLink>
                        </Dropdown.Item>
                    </DropdownButton>
                    <MyContext.Consumer>
                        {({constitutionLink}) => {
                            if (constitutionLink === false) {
                                return (
                                    <a href={"#"} target={"_blank"} className="nav-item nav-link">
                                        <span data-toggle="collapse">Constitution</span>
                                    </a>
                                )
                            } else {
                                return (
                                    <>
                                        <a href={constitutionLink} target={"_blank"} className="nav-item nav-link">
                                            <span data-toggle="collapse">Constitution</span>
                                        </a>
                                    </>
                                )
                            }
                        }
                        }

                    </MyContext.Consumer>

                    <MyContext.Consumer>
                        {({sweetLinks}) => {
                            if (sweetLinks === false) {
                                return (
                                    <DropdownButton id="dropdown-basic-button1" title="Sweet Links"
                                                    className="nav-item nav-link">
                                        <Dropdown.Item href="https://www.amscl.org/" target={"_blank"}>American Sugar
                                            Cane
                                            League</Dropdown.Item>
                                        <Dropdown.Item
                                            href="https://www.lsuagcenter.com/portals/our_offices/departments/audubon-sugar-institute"
                                            target={"_blank"}>Audubon
                                            Sugar Institute</Dropdown.Item>
                                        <Dropdown.Item href="http://www.issct.org/" target={"_blank"}>International
                                            Society of Sugar
                                            Cane
                                            Technologists</Dropdown.Item>
                                        <Dropdown.Item
                                            href="https://www.lsuagcenter.com/portals/our_offices/research_stations/sugar"
                                            target={"_blank"}>St.
                                            Gabriel/Sugar Research Station - LSU
                                            AgCenter</Dropdown.Item>
                                        <Dropdown.Item
                                            href="https://www.ars.usda.gov/southeast-area/new-orleans-la/southern-regional-research-center/"
                                            target={"_blank"}>Southern
                                            Regional Research Center - ARS, USDA</Dropdown.Item>
                                    </DropdownButton>
                                );
                            } else {
                                let links = sweetLinks.links[0];
                                return (
                                    <DropdownButton id="dropdown-basic-button1" title="Sweet Links"
                                                    className="nav-item nav-link">
                                        {Object.keys(links).map(sweetLink => {
                                            return (
                                                <Dropdown.Item href={links[sweetLink].Link}
                                                               target={"_blank"}>{links[sweetLink].Text}
                                                </Dropdown.Item>)
                                        })}
                                    </DropdownButton>
                                )
                            }
                        }
                        }
                    </MyContext.Consumer>

                </div>
            </div>
        )
    } else {
        return (
            <div className="collapse navbar-collapse" id={id}>
                <div className="navbar-nav" id='main-menu'>
                    <NavLink className="nav-item nav-link" exact to="/">
                        <span data-toggle="collapse" data-target={idTarget}>Home</span>
                    </NavLink>
                    <NavLink className="nav-item nav-link" exact to="/journals">
                        <span data-toggle="collapse" data-target={idTarget}>Journals</span>
                    </NavLink>
                    <NavLink className="nav-item nav-link" exact to="/officers">
                        <span data-toggle="collapse" data-target={idTarget}>Officers</span>
                    </NavLink>

                    <NavLink className="nav-item nav-link" exact to="/meetings">
                        <span data-toggle="collapse" data-target={idTarget}>Meetings</span>
                    </NavLink>
                    <DropdownButton id="dropdown-basic-button" title="Divisions" className="nav-item nav-link">
                        <Dropdown.Item>
                            <NavLink exact to="/FLDivision" data-target={idTarget}>Florida Division</NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <NavLink exact to="/LADivision" data-target={idTarget}>Louisiana Division</NavLink>
                        </Dropdown.Item>
                    </DropdownButton>
                    <NavLink className="nav-item nav-link" exact to="/honoraryMembers">
                        <span data-toggle="collapse" data-target={idTarget}>Honorary Members</span>
                    </NavLink>
                    <NavLink className="nav-item nav-link" exact to="/membership">
                        <span data-toggle="collapse" data-target={idTarget}>Membership</span>
                    </NavLink>
                    <MyContext.Consumer>
                        {({constitutionLink}) => {
                            return (
                                <>
                                    <a href={constitutionLink} target={"_blank"} className="nav-item nav-link">
                                        <span data-toggle="collapse">Constitution</span>
                                    </a>
                                </>
                            )
                        }
                        }

                    </MyContext.Consumer>

                    <MyContext.Consumer>
                        {({sweetLinks}) => {
                            if (sweetLinks === false) {
                                return (
                                    <DropdownButton id="dropdown-basic-button1" title="Sweet Links"
                                                    className="nav-item nav-link">
                                        <Dropdown.Item href="https://www.amscl.org/" target={"_blank"}>American Sugar
                                            Cane
                                            League</Dropdown.Item>
                                        <Dropdown.Item
                                            href="https://www.lsuagcenter.com/portals/our_offices/departments/audubon-sugar-institute"
                                            target={"_blank"} rel="noopener noreferrer">Audubon
                                            Sugar Institute</Dropdown.Item>
                                        <Dropdown.Item href="http://www.issct.org/" target={"_blank"}>International
                                            Society of Sugar
                                            Cane
                                            Technologists</Dropdown.Item>
                                        <Dropdown.Item
                                            href="https://www.lsuagcenter.com/portals/our_offices/research_stations/sugar"
                                            target={"_blank"} rel="noopener noreferrer">St.
                                            Gabriel/Sugar Research Station - LSU
                                            AgCenter</Dropdown.Item>
                                        <Dropdown.Item
                                            href="https://www.ars.usda.gov/southeast-area/new-orleans-la/southern-regional-research-center/"
                                            target={"_blank"} rel="noopener noreferrer">Southern
                                            Regional Research Center - ARS, USDA</Dropdown.Item>
                                    </DropdownButton>
                                );
                            } else {
                                let links = sweetLinks.links[0];
                                return (
                                    <DropdownButton id="dropdown-basic-button1" title="Sweet Links"
                                                    className="nav-item nav-link">
                                        {Object.keys(links).map(sweetLink => {
                                            return (
                                                <Dropdown.Item href={links[sweetLink].Link}
                                                               target={"_blank"}>{links[sweetLink].Text}
                                                </Dropdown.Item>)
                                        })}
                                    </DropdownButton>
                                )
                            }
                        }
                        }
                    </MyContext.Consumer>
                </div>
            </div>
        )
    }


}

export default Header;