import React from 'react';


import '../Component.css';
import './Officers.css'
import {MyContext} from "../../config/Theme-Context";

class Officers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }


    componentDidMount() {
        console.log("STATE: " + (this.state))
    }

    render() {

        let tableOrder = ['President', 'First_Vice_President', 'Second_Vice_President', 'Chairman_Of_Agriculture', 'Chairman_Of_Manufacturing',
            'Chairman_At_Large', 'Past_President', 'Secretary_Treasurer', 'General_Secretary_Treasurer'];

        return (
            <div className="App-Component">
                <div id="officersPage">
                    <h3 className={"PageHeader"}>Executive Officers of the American Society of Sugar Cane
                        Technologists</h3>

                    <div id="officersTable">
                        <table className="table table-striped table-bordered table-responsive-md"
                               id="executiveOfficersTable">
                            <thead>
                            <tr className={"columnHeaders"}>
                                <th scope="col"><h5>Florida Division</h5></th>
                                <th scope="col" id={"positionHeader"}><h5>Position</h5></th>
                                <th scope="col"><h5>Louisiana Division</h5></th>
                            </tr>
                            </thead>
                            <tbody>

                            <MyContext.Consumer>
                                {({officers}) => {
                                    if (officers === false) {
                                        return (
                                            <>
                                                Loading...
                                            </>
                                        )
                                    } else {
                                        return (
                                            <>
                                                {tableOrder.map(position => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                {this.checkUndefinedElements(officers[position][0])}
                                                                <td id="officerTitle">{position.replace(/_/g, ' ')}</td>
                                                                {this.checkUndefinedElements(officers[position][1])}
                                                            </tr>
                                                        </>
                                                    )
                                                })}
                                            </>

                                        )

                                    }
                                }}

                            </MyContext.Consumer>
                            </tbody>
                        </table>
                    </div>
                    <br/>
                    <h6><u>
                        ASSCT Members Holding Positions with the
                        International Society of Sugar Cane Technologists</u>
                    </h6>

                    <MyContext.Consumer>
                        {({ISSCT_Officers}) => {
                            if (ISSCT_Officers === false) {
                                return (
                                    <>
                                        Loading...
                                    </>
                                )
                            } else {
                                return (
                                    <table id="InternationalMembers" className="table-sm table-striped table-bordered">
                                        {Object.keys(ISSCT_Officers).map(ISSCT_Officer => {
                                            return (
                                                <thead>
                                                <tr>
                                                    <th scope="col">{ISSCT_Officer}</th>
                                                    <th scope="col">{ISSCT_Officers[ISSCT_Officer][0].Text}
                                                    </th>

                                                </tr>
                                                </thead>
                                            )
                                        })}
                                    </table>
                                )
                            }
                        }
                        }
                    </MyContext.Consumer>

                    <MyContext.Consumer>
                        {({life_members}) => {
                            return (
                                <>
                                    <h6><u>Honorary Life Members
                                        of the
                                        International Society of Sugar Cane Technologists</u></h6>
                                    <ul>
                                        {Object.keys(life_members).map(life_member => {
                                            return (
                                                <li>
                                                    {life_member}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </>
                            )
                        }
                        }
                    </MyContext.Consumer>

                    <b><u>Click on the following links for a history of Officers</u></b><br/>
                    <a href="https://firebasestorage.googleapis.com/v0/b/louisianadivsionassct.appspot.com/o/Florida%20Division%20Files%2FFL%20Officers%20Since%201971.pdf?alt=media&token=008f91e6-baa6-4917-bc26-990e69114d35"
                       target={"_blank"}>
                        Officers of the Florida Division Executive Committee since 1971</a><br/>
                    <a href="https://firebasestorage.googleapis.com/v0/b/louisianadivisionassct.appspot.com/o/Louisiana%20Division%20Files%2FLA%20Officers%20since%201953.pdf?alt=media&token=c51a401f-7221-4d26-9862-8f396c887f6c"
                       target={"_blank"}>
                        Officers of the Louisiana Division Executive Committee since 1953</a><br/><br/>
                </div>
            </div>
        );

    }

    checkUndefinedElements(officer) {
        if (officer !== undefined && officer !== null) {
            if (officer["Site_Name"] !== null && officer["Site_Name"] !== "") {
                return (
                    <td>
                        <h5 className="officerName">{officer["Name"]}</h5>
                        <span className={"officerInfo"}>
                        {officer["Site_Name"]}<br/>
                            {officer["Address"]}<br/>
                            {officer["CityAndState"]}<br/>
                        <a href={"mailto:" + officer["Email"]}> {officer["Email"]}</a><br/>
                            {officer["Phone_Number"]}
                        </span>
                    </td>
                )
            } else {
                return (
                    <td>
                        <h5 className="officerName">{officer["Name"]}</h5>
                        <span className={"officerInfo"}>
                        {officer["Address"]}<br/>
                            {officer["CityAndState"]}<br/>
                        <a href={officer["Email"]}> {officer["Email"]}</a><br/>
                            {officer["Phone_Number"]}
                        </span>
                    </td>
                )
            }
        }
    }

}


export default Officers;
