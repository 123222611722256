import React from 'react';

import '../Component.css';
import './Divisions.css'
import floridaMembershipForm from '../../resources/PDFs/Florida Membership Form.pdf'
import {FLdb} from "../../config/FLConfig";
import sugarProduction from "../../resources/PDFs/MESSAGE FROM THE 2015 EXECUTIVE COMMITTEE_final_RM_LB_030816.pdf"
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import constitutionAndByLaws from "../../resources/PDFs/Constitution Amended June 2017.pdf";
import {MyContext} from "../../config/Theme-Context";
import {NavLink} from 'react-router-dom'


class FLDivision extends React.Component {

    selectedSection = "MainMenu";

    constructor(props) {
        super(props);
        this.state = {
            render: false
        };
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let urlParams = new URLSearchParams(window.location.search).toString();
        let destinationPage;

        if (urlParams === "destination=annualMeetingPage") {
            destinationPage = "FloridaAnnualMeetingPage";
        } else {
            destinationPage = "MainMenu"
        }

        if (document.getElementById("Officers") !== null && document.getElementById("Officers") !== undefined) {
            document.getElementById("Officers").classList.remove("btn", "btn-primary");
        }

        if (document.getElementById("FloridaDivisionMeetings") !== null && document.getElementById("FloridaDivisionMeetings") !== undefined) {
            document.getElementById("FloridaDivisionMeetings").classList.remove("btn", "btn-primary");
        }


        if (document.getElementById("Scholarship&ScienceFair") !== null && document.getElementById("Scholarship&ScienceFair") !== undefined) {
            document.getElementById("Scholarship&ScienceFair").classList.remove("btn", "btn-primary");
            document.getElementById("Scholarship&ScienceFair").style.fontWeight = "500";
            document.getElementById("Scholarship&ScienceFair").style.color = "black";
        }

        if (document.getElementById(destinationPage) !== null && document.getElementById(destinationPage) !== undefined) {
            this.updateSelectedSection(destinationPage, true);
        }
    }

    async loadData() {
        let pageHeaders = [];
        let statePushObject = {};

        FLdb.collection("FloridaPage").get().then(snapshot => {
            snapshot.forEach(doc => {
                if (!(doc.id in pageHeaders)) {
                    pageHeaders.push(doc.id)
                }
                let sectionId = doc.id;

                statePushObject[sectionId] = doc.data();

            });
            let first = "Main Menu";
            pageHeaders.sort(function (x, y) {
                return x === first ? -1 : y === first ? 1 : 0;
            });
            this.setState({divisionInfo: statePushObject});
            this.setState({pageHeaders: pageHeaders});
            this.setState({render: true});

        });


    }

    render() {
        if (!this.state.render) {
            return (
                <>
                    Loading...
                </>
            )
        }

        if (window.screen.width >= 992) {
            return (
                <div className="App-Component">
                    <div className={"divisionsPage"}>
                        <div id="FloridaPage">
                            <div className={"row"}>
                                <div className={"col-4"}/>

                                <div className={"col-4"}>
                                    <h2 className={"PageHeader"}>
                                        Florida Division
                                    </h2>
                                </div>
                                <div className={"donationButton col"}>
                                    <button className={"btn btn-warning"}>
                                        <NavLink exact to={"/donate"}> Pay or Donate</NavLink>
                                    </button>
                                </div>
                            </div>


                            <div className={"row"}>
                                <table className="table table-bordered table-responsive-md">
                                    <tbody>
                                    <tr>
                                        {
                                            this.state.pageHeaders.map(headers => {
                                                if (headers === "Scholarship") {
                                                    return;
                                                }
                                                if (headers === "Sugar Production and the Environment") {
                                                    return (
                                                        <td id={headers.toString().replace(/ /g, "")} key={headers}>
                                                            <a href={sugarProduction} target={"_blank"}>
                                                                <h5>
                                                                    {headers}
                                                                </h5>
                                                            </a>

                                                        </td>
                                                    )
                                                } else if (headers === "Officers") {
                                                    return (
                                                        <>
                                                            <td id={headers.toString().replace(/ /g, "") + "1"}
                                                                key={headers}>
                                                                <DropdownButton
                                                                    id={headers.toString().replace(/ /g, "")}
                                                                    title={headers}
                                                                    className="nav-item nav-link FLDropDown">
                                                                    <Dropdown.Item>
                                                                        <NavLink exact to="/officers">Current
                                                                            Officers</NavLink>
                                                                    </Dropdown.Item>

                                                                    <Dropdown.Item
                                                                        href={"https://firebasestorage.googleapis.com/v0/b/louisianadivsionassct.appspot.com/o/FloridaDivisionDocuments%2FOfficer%20since%201971.pdf?alt=media&token=19438b5c-7a10-4b14-a3ad-6611db556673"}
                                                                        target={"_blank"} rel="noopener noreferrer">
                                                                        Past Officers
                                                                    </Dropdown.Item>

                                                                </DropdownButton>
                                                            </td>
                                                        </>
                                                    )
                                                } else if (headers === "Florida Division Meetings") {
                                                    return (
                                                        <>
                                                        </>
                                                    )
                                                    // return (
                                                    //     <>
                                                    //         <td id={headers.toString().replace(/ /g, "") + "1"}
                                                    //             key={headers}>
                                                    //             <DropdownButton
                                                    //                 id={headers.toString().replace(/ /g, "")}
                                                    //                 title={headers}
                                                    //                 className="nav-item nav-link FLDropDown">
                                                    //                 <Dropdown.Item onClick={() => {
                                                    //                     this.updateSelectedSection("FloridaDivisionMeetings".toString().replace(/ /g, ""), false)
                                                    //                 }}>
                                                    //
                                                    //                     {this.state.divisionInfo[headers]["dropDownPages"][0]}
                                                    //                 </Dropdown.Item>
                                                    //
                                                    //                 <Dropdown.Item
                                                    //                     onClick={() => {
                                                    //                         this.updateSelectedSection("PastFloridaDivisionMeetings".toString().replace(/ /g, ""), false)
                                                    //                     }}>
                                                    //                     {this.state.divisionInfo[headers]["dropDownPages"][1]}
                                                    //                 </Dropdown.Item>
                                                    //
                                                    //             </DropdownButton>
                                                    //         </td>
                                                    //     </>

                                                } else if (this.state.divisionInfo[headers].hover) {
                                                    return (
                                                        <>
                                                            <td id={headers.toString().replace(/ /g, "") + "1"}
                                                                key={headers}>
                                                                <DropdownButton
                                                                    id={headers.toString().replace(/ /g, "")}
                                                                    title={headers}
                                                                    className="nav-item nav-link FLDropDown">
                                                                    {this.state.divisionInfo[headers].dropDownPages.map(dropDown => {
                                                                        return (
                                                                            <Dropdown.Item onClick={() => {
                                                                                this.updateSelectedSection(dropDown.toString().replace(/ /g, ""), false)
                                                                            }}>
                                                                                {dropDown}
                                                                            </Dropdown.Item>
                                                                        )
                                                                    })}
                                                                </DropdownButton>
                                                            </td>
                                                        </>
                                                    )
                                                } else if (headers === "Florida Annual Meeting Page") {
                                                    return (
                                                        <td id={headers.toString().replace(/ /g, "")} key={headers}
                                                            onClick={() => {
                                                                this.updateSelectedSection(headers.toString().replace(/ /g, ""), true)
                                                            }}>
                                                            <h5>
                                                                Florida Annual Meetings
                                                            </h5>
                                                        </td>

                                                    )
                                                } else {
                                                    return (
                                                        <td id={headers.toString().replace(/ /g, "")} key={headers}
                                                            onClick={() => {
                                                                this.updateSelectedSection(headers.toString().replace(/ /g, ""), true)
                                                            }}>
                                                            <h5>
                                                                {headers}
                                                            </h5>
                                                        </td>

                                                    )
                                                }

                                            })}
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div id={"MainMenuSection"} className={"d-none"}>
                            {
                                this.state.divisionInfo["Main Menu"]["Links"].map(link => {

                                    return (
                                        Object.keys(link).map(key => {
                                            console.log(key)
                                            if (key === "Click here for the Florida Division Membership Application/Renewal form") {
                                                return (
                                                    <>
                                                        <h5>
                                                            <a href={floridaMembershipForm} target={"_blank"}>
                                                                {key}
                                                            </a>
                                                        </h5>
                                                    </>
                                                )
                                            } else {
                                                return (
                                                    <>
                                                        <h5>
                                                            <a href={link[key]} target={"_blank"}>- {key}</a>
                                                        </h5>
                                                    </>
                                                )
                                            }
                                        })
                                    )


                                })}
                        </div>
                        <div id={"FloridaAnnualMeetingPageSection"} className={"d-none"}>
                            {
                                this.state.divisionInfo["Florida Annual Meeting Page"]["Meetings"].map(meeting => {
                                    return (
                                        <>
                                            <b>
                                                <u><h4>Meetings</h4></u>
                                            </b>
                                            <h5>
                                                {meeting["Title"]}
                                            </h5>
                                            <h6>
                                                {meeting["Date"]}
                                            </h6>
                                            <h6>
                                                {meeting["Venue"]}
                                            </h6>
                                            <h6>
                                                {meeting["Address"]}
                                            </h6>
                                        </>
                                    )
                                })
                            }

                            {this.state.divisionInfo["Florida Annual Meeting Page"]["Links"].map(link => {
                                return (
                                    Object.keys(link).map(key => {
                                        return (
                                            <>
                                                <h5>
                                                    <a href={link[key]} target={"_blank"}>{key}</a><br/>
                                                </h5>
                                            </>
                                        )
                                    })
                                )


                            })
                            }

                            {
                                this.state.divisionInfo["Florida Annual Meeting Page"]["Footer Info"].map(footerString => {
                                    return (
                                        <>
                                            <h6>
                                                <i>{footerString}</i>
                                            </h6>
                                        </>
                                    )
                                })
                            }
                        </div>


                        <div id={"FloridaDivisionMeetingsSection"} className={"d-none"}>
                            <h5>
                                Future Florida Division Meetings
                            </h5>

                            <MyContext.Consumer>
                                {({meetings}) => {
                                    if (meetings === false) {
                                        return (<>Loading...</>)
                                    } else {

                                        let floridaMeetings = this.state.divisionInfo["Florida Division Meetings"]["FutureMeetings"];
                                        return (
                                            <table className="table table-striped table-responsive-md FLTable">

                                                {floridaMeetings.map(meeting => {
                                                    let keys = ["Title", "Date", "Venue", "Address"];

                                                    return (
                                                        <tr>
                                                            <td>
                                                                <h6 className={"meeting"}>
                                                                    {
                                                                        keys.map(key => {
                                                                            return (
                                                                                <>
                                                                                    {meeting[key]}<br/>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </h6>
                                                            </td>
                                                        </tr>
                                                    )

                                                })}
                                            </table>
                                        )
                                    }
                                }
                                }
                            </MyContext.Consumer>

                        </div>

                        <div id={"PastFloridaDivisionMeetingsSection"} className={"d-none"}>
                            <h5>
                                Past Florida Division Meetings
                            </h5>

                            <MyContext.Consumer>
                                {({meetings}) => {
                                    if (meetings === false) {
                                        return (<>Loading...</>)
                                    } else {

                                        let floridaMeetings = this.state.divisionInfo["Florida Division Meetings"]["PastMeetings"];
                                        return (
                                            <table className="table table-striped table-responsive-md FLTable">

                                                {floridaMeetings.map(meeting => {
                                                    let keys = ["Title", "Date", "Venue", "Address"];

                                                    return (
                                                        <tr>
                                                            <td>
                                                                <h6 className={"meeting"}>
                                                                    {
                                                                        keys.map(key => {
                                                                            return (
                                                                                <>
                                                                                    {meeting[key]}<br/>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </h6>
                                                            </td>
                                                        </tr>
                                                    )

                                                })}
                                            </table>
                                        )
                                    }
                                }
                                }
                            </MyContext.Consumer>

                        </div>

                        <div id={"MembershipSection"} className={"d-none"}>
                            <h5>Click on the following link for membership application forms:</h5>

                            <MyContext.Consumer>
                                {({FLMemberShipForm}) => {
                                    if (FLMemberShipForm === false || FLMemberShipForm === "") {
                                        return (
                                            <h5>
                                                - Florida Membership Form
                                            </h5>
                                        )
                                    } else {
                                        return (
                                            <>
                                                <h5>
                                                    <a href={FLMemberShipForm} target={"_blank"}>
                                                        - Florida Membership Form</a>
                                                </h5>
                                            </>
                                        )
                                    }
                                }
                                }

                            </MyContext.Consumer>



                            <h5>Click on the following link for the ASSCT Constitution and By-Laws:</h5>
                            <h5>
                                <a href={constitutionAndByLaws} target={"_blank"}>
                                    - Constitution and By-Laws</a>
                            </h5>
                        </div>

                        <div id={"FishingForScholarsAnnualFishingTripSection"} className={"d-none"}>
                            <h4>
                                <u>Fishing For Scholars Annual Fishing Trip</u>
                            </h4>
                            {
                                this.state.divisionInfo["Scholarship & Science Fair"]["Fishing For Scholars Annual Fishing trip"].sort
                                ((a, b) => {
                                    return Object.keys(b)[0] - Object.keys(a)[0]
                                }).map(tripYear => {
                                        return (
                                            Object.keys(tripYear).map(key => {
                                                return (
                                                    <>
                                                        <h5>
                                                            <a href={tripYear[key]} target={"_blank"}>
                                                                - {key} Annual Fishing Trip
                                                            </a>

                                                        </h5>
                                                    </>
                                                )

                                            }))
                                    }
                                )}
                        </div>

                        <div id={"ScholarshipWinnersSection"} className={"d-none"}>
                            <h4>
                                <u>Florida Division Scholarship</u>
                            </h4>


                            <div className={"row"}>
                                <div className={"col-4"}>
                                    {
                                        this.state.divisionInfo["Scholarship & Science Fair"]["Scholarship Winners"].sort
                                        ((a, b) => {
                                            return Object.keys(b)[0] - Object.keys(a)[0]
                                        }).map(scholarshipYear => {
                                                return (
                                                    Object.keys(scholarshipYear).map(key => {
                                                        return (
                                                            <>
                                                                <h5>
                                                                    <a href={scholarshipYear[key]} target={"_blank"}>
                                                                        - {key} Scholarship Winner
                                                                    </a>

                                                                </h5>
                                                            </>
                                                        )

                                                    }))
                                            }
                                        )}

                                    <h5>
                                        Application Forms
                                    </h5>

                                    {this.state.divisionInfo["Scholarship & Science Fair"]["ScholarshipForms"].map(form => {
                                        return (
                                            Object.keys(form).map(formName => {
                                                return (
                                                    <>
                                                        <a href={form[formName]} target={"_blank"}> {formName}</a>
                                                    </>
                                                )
                                            }))

                                    })}
                                </div>

                                <div className={"col"}>
                                    <img
                                        src={this.state.divisionInfo["Scholarship & Science Fair"]["Scholarship Winners Flyer"]}/>
                                </div>

                            </div>
                        </div>


                        <div id={"ScienceFairWinnersSection"} className={"d-none"}>
                            <h4>
                                <u>Florida Division Science Fair Winners</u>
                            </h4>
                            {
                                this.state.divisionInfo["Scholarship & Science Fair"]["Science Fair Winners"].sort
                                ((a, b) => {
                                    return Object.keys(b)[0] - Object.keys(a)[0]
                                }).map(scholarshipYear => {
                                        return (
                                            Object.keys(scholarshipYear).map(key => {
                                                return (
                                                    <>
                                                        <h5>
                                                            <a href={scholarshipYear[key]} target={"_blank"}>
                                                                - {key} Science Fair Winner
                                                            </a>

                                                        </h5>
                                                    </>
                                                )

                                            }))
                                    }
                                )}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="App-Component">
                    <div className={"divisionsPage"}>
                        <div id="FloridaPage">
                            <div className={"row"}>
                                <div className={"col-4"}/>

                                <div className={"col-4"}>
                                    <h2 className={"PageHeader"}>
                                        Florida Division
                                    </h2>
                                </div>
                                <div className={"donationButton col-4"}>
                                    <button className={"btn btn-warning"}>
                                        <NavLink exact to={"/donate"}> Pay or Donate</NavLink>
                                    </button>
                                </div>
                            </div>


                            <div className={"row"}>
                                <table className="table table-bordered table-responsive-md">
                                    <tbody>
                                    <tr>
                                        {
                                            this.state.pageHeaders.map(headers => {
                                                if (headers === "Scholarship") {
                                                    return;
                                                }
                                                if (headers === "Sugar Production and the Environment") {
                                                    return (
                                                        <td id={headers.toString().replace(/ /g, "")} key={headers}>
                                                            <a href={sugarProduction} target={"_blank"}>
                                                                <h5>
                                                                    {headers}
                                                                </h5>
                                                            </a>

                                                        </td>
                                                    )
                                                } else if
                                                (headers === "Officers") {
                                                    return (
                                                        <>
                                                            <td id={headers.toString().replace(/ /g, "") + "1"}
                                                                key={headers}>
                                                                <DropdownButton
                                                                    id={headers.toString().replace(/ /g, "")}
                                                                    title={headers}
                                                                    className="nav-item nav-link FLDropDown">
                                                                    <Dropdown.Item className="FLDropDown">
                                                                        <NavLink exact to="/officers">Current
                                                                            Officers</NavLink>
                                                                    </Dropdown.Item>

                                                                    <Dropdown.Item className="FLDropDown"
                                                                                   href={"https://firebasestorage.googleapis.com/v0/b/louisianadivsionassct.appspot.com/o/FloridaDivisionDocuments%2FOfficer%20since%201971.pdf?alt=media&token=19438b5c-7a10-4b14-a3ad-6611db556673"}
                                                                                   target={"_blank"}>
                                                                        Past Officers
                                                                    </Dropdown.Item>

                                                                </DropdownButton>
                                                            </td>
                                                        </>
                                                    )
                                                } else if (headers === "Florida Division Meetings") {
                                                    return (
                                                        <>
                                                        </>
                                                    )
                                                    // return (
                                                    //     <>
                                                    //         <td id={headers.toString().replace(/ /g, "") + "1"}
                                                    //             key={headers}>
                                                    //             <DropdownButton
                                                    //                 id={headers.toString().replace(/ /g, "")}
                                                    //                 title={headers}
                                                    //                 className="nav-item nav-link FLDropDown">
                                                    //                 <Dropdown.Item onClick={() => {
                                                    //                     this.updateSelectedSection("FloridaDivisionMeetings".toString().replace(/ /g, ""), false)
                                                    //                 }}>
                                                    //
                                                    //                     {this.state.divisionInfo[headers]["dropDownPages"][0].substring(0, 15)}
                                                    //                 </Dropdown.Item>
                                                    //
                                                    //                 <Dropdown.Item
                                                    //                     onClick={() => {
                                                    //                         this.updateSelectedSection("PastFloridaDivisionMeetings".toString().replace(/ /g, ""), false)
                                                    //                     }}>
                                                    //                     {this.state.divisionInfo[headers]["dropDownPages"][1].substring(0, 12)}
                                                    //                 </Dropdown.Item>
                                                    //
                                                    //             </DropdownButton>
                                                    //         </td>
                                                    //     </>
                                                    // )
                                                } else if (this.state.divisionInfo[headers].hover) {
                                                    return (
                                                        <>
                                                            <td id={headers.toString().replace(/ /g, "") + "1"}
                                                                key={headers}>
                                                                <DropdownButton
                                                                    id={headers.toString().replace(/ /g, "")}
                                                                    title={headers}
                                                                    className="nav-item nav-link FLDropDown">
                                                                    {this.state.divisionInfo[headers].dropDownPages.map(dropDown => {
                                                                        return (
                                                                            <>
                                                                                <Dropdown.Item className="FLDropDown"
                                                                                               onClick={() => {
                                                                                                   this.updateSelectedSection(dropDown.toString().replace(/ /g, ""), false)
                                                                                               }}>
                                                                                    {dropDown.substring(0, 21)} <br/>
                                                                                </Dropdown.Item>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </DropdownButton>
                                                            </td>
                                                        </>
                                                    )
                                                } else if (headers === "Florida Annual Meeting Page") {
                                                    return (
                                                        <td id={headers.toString().replace(/ /g, "")} key={headers}
                                                            onClick={() => {
                                                                this.updateSelectedSection(headers.toString().replace(/ /g, ""), true)
                                                            }}>
                                                            <h5>
                                                                Florida Annual Meetings
                                                            </h5>
                                                        </td>

                                                    )
                                                } else {
                                                    return (
                                                        <td id={headers.toString().replace(/ /g, "")} key={headers}
                                                            onClick={() => {
                                                                this.updateSelectedSection(headers.toString().replace(/ /g, ""), true)
                                                            }}>
                                                            <h5>
                                                                {headers}
                                                            </h5>
                                                        </td>

                                                    )
                                                }

                                            })}
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div id={"MainMenuSection"} className={"d-none"}>
                            {
                                this.state.divisionInfo["Main Menu"]["Links"].map(link => {

                                    return (
                                        Object.keys(link).map(key => {
                                                return (
                                                    <>
                                                        <h5>
                                                            <a href={link[key]} target={"_blank"} rel="noopener noreferrer">- {key}</a>
                                                        </h5>
                                                    </>
                                                )
                                        })
                                    )


                                })}
                        </div>

                        <div id={"PastFloridaDivisionMeetingsSection"} className={"d-none"}>
                            <h5>
                                Past Florida Division Meetings
                            </h5>

                            <MyContext.Consumer>
                                {({meetings}) => {
                                    if (meetings === false) {
                                        return (<>Loading...</>)
                                    } else {

                                        let floridaMeetings = this.state.divisionInfo["Florida Division Meetings"]["PastMeetings"];
                                        return (
                                            <table className="table table-striped table-responsive-md FLTable">

                                                {floridaMeetings.map(meeting => {
                                                    let keys = ["Title", "Date", "Venue", "Address"];

                                                    return (
                                                        <tr>
                                                            <td>
                                                                <h6 className={"meeting"}>
                                                                    {
                                                                        keys.map(key => {
                                                                            return (
                                                                                <>
                                                                                    {meeting[key]}<br/>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </h6>
                                                            </td>
                                                        </tr>
                                                    )

                                                })}
                                            </table>
                                        )
                                    }
                                }
                                }
                            </MyContext.Consumer>

                        </div>


                        <div id={"FloridaAnnualMeetingPageSection"} className={"d-none"}>
                            {
                                this.state.divisionInfo["Florida Annual Meeting Page"]["Meetings"].map(meeting => {
                                    return (
                                        <>
                                            <b>
                                                <u><h4>Meetings</h4></u>
                                            </b>
                                            <h5>
                                                {meeting["Title"]}
                                            </h5>
                                            <h6>
                                                {meeting["Date"]}
                                            </h6>
                                            <h6>
                                                {meeting["Venue"]}
                                            </h6>
                                            <h6>
                                                {meeting["Address"]}
                                            </h6>
                                        </>
                                    )
                                })
                            }

                            {this.state.divisionInfo["Florida Annual Meeting Page"]["Links"].map(link => {
                                return (
                                    Object.keys(link).map(key => {
                                        return (
                                            <>
                                                <h5>
                                                    <a href={link[key]} target={"_blank"}>{key}</a><br/>
                                                </h5>
                                            </>
                                        )
                                    })
                                )


                            })
                            }

                            {
                                this.state.divisionInfo["Florida Annual Meeting Page"]["Footer Info"].map(footerString => {
                                    return (
                                        <>
                                            <h6>
                                                <i>{footerString}</i>
                                            </h6>
                                        </>
                                    )
                                })
                            }
                        </div>

                        <div id={"FloridaDivisionMeetingsSection"} className={"d-none"}>
                            <h5>
                                Future Florida Division Meetings
                            </h5>

                            <MyContext.Consumer>
                                {({meetings}) => {
                                    if (meetings === false) {
                                        return (<>Loading...</>)
                                    } else {

                                        let floridaMeetings = this.state.divisionInfo["Florida Division Meetings"]["FutureMeetings"];
                                        return (
                                            <table className="table table-striped table-responsive FLTable">

                                                {floridaMeetings.map(meeting => {
                                                    let keys = ["Title", "Date", "Venue", "Address"];

                                                    return (
                                                        <tr>
                                                            <td>
                                                                <h6 className={"meeting"}>
                                                                    {
                                                                        keys.map(key => {
                                                                            return (
                                                                                <>
                                                                                    {meeting[key]}<br/>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </h6>
                                                            </td>
                                                        </tr>
                                                    )

                                                })}
                                            </table>
                                        )
                                    }
                                }
                                }
                            </MyContext.Consumer>

                        </div>

                        <div id={"MembershipSection"} className={"d-none"}>
                            <h5>Click on the following link for membership application forms:</h5>

                            <MyContext.Consumer>
                                {({FLMemberShipForm}) => {
                                    if (FLMemberShipForm === false || FLMemberShipForm === "") {
                                        return (
                                            <h5>
                                                - Florida Membership Form
                                            </h5>
                                        )
                                    } else {
                                        return (
                                            <>
                                                <h5>
                                                    <a href={FLMemberShipForm} target={"_blank"}>
                                                        - Florida Membership Form</a>
                                                </h5>
                                            </>
                                        )
                                    }
                                }
                                }

                            </MyContext.Consumer>


                            <h5>Click on the following link for the ASSCT Constitution and By-Laws:</h5>
                            <h5>
                                <a href={constitutionAndByLaws} target={"_blank"}>
                                    - Constitution and By-Laws</a>
                            </h5>
                        </div>

                        <div id={"FishingForScholarsAnnualFishingTripSection"} className={"d-none"}>
                            <h4>
                                <u>Fishing For Scholars Annual Fishing Trip</u>
                            </h4>

                            <img
                                src={this.state.divisionInfo["Scholarship & Science Fair"]["Fishing For Scholars Flyer"]}/>

                            <div className={"row"}>
                                <div className={"col-12"}>
                                    {
                                        this.state.divisionInfo["Scholarship & Science Fair"]["Fishing For Scholars Annual Fishing trip"].sort
                                        ((a, b) => {
                                            return Object.keys(b)[0] - Object.keys(a)[0]
                                        }).map(tripYear => {
                                                return (
                                                    Object.keys(tripYear).map(key => {
                                                        return (
                                                            <>
                                                                <h5>
                                                                    <a href={tripYear[key]} target={"_blank"}>
                                                                        - {key} Annual Fishing Trip
                                                                    </a>

                                                                </h5>
                                                            </>
                                                        )

                                                    }))
                                            }
                                        )}
                                </div>
                            </div>
                        </div>

                        <div id={"ScholarshipWinnersSection"} className={"d-none"}>
                            <h4>
                                <u>Florida Division Scholarship</u>
                            </h4>

                            <img
                                src={this.state.divisionInfo["Scholarship & Science Fair"]["Scholarship Winners Flyer"]}/>

                            {
                                this.state.divisionInfo["Scholarship & Science Fair"]["Scholarship Winners"].sort
                                ((a, b) => {
                                    return Object.keys(b)[0] - Object.keys(a)[0]
                                }).map(scholarshipYear => {
                                        return (
                                            Object.keys(scholarshipYear).map(key => {
                                                return (
                                                    <>
                                                        <h5>
                                                            <a href={scholarshipYear[key]} target={"_blank"}>
                                                                - {key} Scholarship Winner
                                                            </a>

                                                        </h5>
                                                    </>
                                                )

                                            }))
                                    }
                                )}

                            <h5>
                                Application Forms
                            </h5>

                            {this.state.divisionInfo["Scholarship & Science Fair"]["ScholarshipForms"].map(form => {
                                return (
                                    Object.keys(form).map(formName => {
                                        return (
                                            <>
                                                <a href={form[formName]} target={"_blank"}> {formName}</a>
                                            </>
                                        )
                                    }))

                            })}
                        </div>

                        <div id={"ScienceFairWinnersSection"} className={"d-none"}>
                            <h4>
                                <u>Florida Division Science Fair</u>
                            </h4>
                            {
                                this.state.divisionInfo["Scholarship & Science Fair"]["Science Fair Winners"].sort
                                ((a, b) => {
                                    return Object.keys(b)[0] - Object.keys(a)[0]
                                }).map(scholarshipYear => {
                                        return (
                                            Object.keys(scholarshipYear).map(key => {
                                                return (
                                                    <>
                                                        <h5>
                                                            <a href={scholarshipYear[key]} target={"_blank"}>
                                                                - {key} Science Fair Winner
                                                            </a>

                                                        </h5>
                                                    </>
                                                )

                                            }))
                                    }
                                )}
                        </div>
                    </div>
                </div>
            );
        }


    }

    updateSelectedSection(ID, selectMenuItem) {
        console.debug("update called from: " + ID);

        if (document.getElementById(this.selectedSection) !== null && document.getElementById(this.selectedSection) !== undefined) {
            document.getElementById(this.selectedSection).classList.remove("selected");
        }

        if (selectMenuItem) {
            // The following line marks the menu box for selection not the page's section
            document.getElementById(ID).classList.add("selected");
        }

        document.getElementById(this.selectedSection + "Section").classList.add("d-none");
        document.getElementById(ID + "Section").classList.remove("d-none");

        this.selectedSection = ID;
    }
}


export default FLDivision;
