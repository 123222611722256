import React from 'react';
import '../Component.css';
import './Membership.css'

import {NavLink} from 'react-router-dom'
import {MyContext} from "../../config/Theme-Context";

class Membership extends React.Component {


    render() {

        return (
            <div className="App-Component">
                <div id="membershipFormsPage">
                    <div className={"row"}>
                        <div className={"col-4"}>
                        </div>
                        <div className={"col-4"}>
                            <h2 className={"PageHeader"}>
                                Membership Forms
                            </h2>
                        </div>
                        <div className={"donationButton col"}>
                            <button className={"btn btn-warning"}>
                                <NavLink exact to={"/donate"}> Pay or Donate</NavLink>
                            </button>
                        </div>
                    </div>
                    <p id="membershipFormText">
                        Individuals, institutions and companies interested in joining the the American Society of Sugar
                        Cane Technologists can obtain a membership form for either the Louisiana or Florida Division
                        through this website. The membership forms include information on categories and costs of
                        joining. A link to our Constitution and By-Laws is provided below.
                    </p>
                    <h5>Click on the following links for membership application forms:</h5>

                    <MyContext.Consumer>
                    {({FLMemberShipForm}) => {
                        if (FLMemberShipForm === false || FLMemberShipForm === "") {
                            return (
                                <h5>
                                    - Florida Membership Form
                                </h5>
                            )
                        } else {
                            return (
                                <>
                                    <h5>
                                        <a href={FLMemberShipForm} target={"_blank"}>
                                            - Florida Membership Form</a>
                                    </h5>
                                </>
                            )
                        }
                    }
                    }

                </MyContext.Consumer>

                    <MyContext.Consumer>
                        {({LAMemberShipForm}) => {
                            if (LAMemberShipForm === false || LAMemberShipForm === "") {
                                return (
                                    <h5>
                                        - Louisiana Membership Form
                                    </h5>
                                )
                            } else {
                                return (
                                    <>
                                        <h5>
                                            <a href={LAMemberShipForm} target={"_blank"}>
                                                - Louisiana Membership Form</a>
                                        </h5>
                                    </>
                                )
                            }
                        }
                        }

                    </MyContext.Consumer>


                    <br/>
                    <h5>Click on the following link for the ASSCT Constitution and By-Laws:</h5>

                    <MyContext.Consumer>
                        {({constitutionLink}) => {
                            if (constitutionLink === false) {
                                return (
                                    <h5>
                                        <a href={constitutionLink} target={"_blank"}>
                                            - Constitution and By-Laws</a>
                                    </h5>
                                )
                            } else {
                                return (
                                    <>
                                        <h5>
                                            <a href={constitutionLink} target={"_blank"}>
                                                - Constitution and By-Laws</a>
                                        </h5>
                                    </>
                                )
                            }
                        }
                        }

                    </MyContext.Consumer>


                </div>
            </div>
        );

    }


}


export default Membership;
