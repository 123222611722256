import React from 'react';
import $ from "jquery";
import Hammer from "hammerjs";
import Carousel from "bootstrap/js/src/carousel";
import {NavLink} from 'react-router-dom'

import '../Component.css';
import './Home.css'
import {MyContext} from "../../config/Theme-Context";

class Home extends React.Component {


    render() {
        let carouselId = 'homePageCarousel';
        let carouselDataTarget = '#' + carouselId;
        return (this.generatePage(carouselId, carouselDataTarget))


    }


    generatePage(carouselId, carouselDataTarget) {
        if (window.screen.width < 992) {
            return (
                <div className="App-Component">
                    <div className={"HomePage"}>
                        <div className={"row"}>
                            <div className={"col-8"}/>
                            <div className={"donationButton col-4 homeHeader"}>
                                <button className={"btn btn-warning donateButton"}>
                                    <NavLink exact to={"/donate"}> Pay or Donate</NavLink>
                                </button>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-2"}/>

                            <div className={"col-8 homeHeader"}>
                                <h2 className={"PageHeader homeHeader"}>American Society of Sugar Cane
                                    Technologists<br/>
                                </h2>
                            </div>
                        </div>

                        <h6 id="subHeadLine">
                            Dedicated to the advancement for the mainland sugarcane industry in the United States of
                            America
                        </h6>


                        <div>
                            <div id="aboutText">
                                <p>
                                    The American Society of Sugarcane Technologists (ASSCT) is a society interested in
                                    the
                                    general
                                    study of the cane sugar industry in the United States and the dissemination of
                                    information
                                    to
                                    members of the organization through meetings and publications. The ASSCT is
                                    comprised of
                                    two
                                    major branches, the Florida Division and the Louisiana Division...
                                    <a data-toggle="modal"
                                       data-target="#exampleModalLong" id={"learnMoreText"}>
                                        <br/>Click to Learn More . . .
                                    </a>

                                </p>
                            </div>
                        </div>

                        <div id={carouselId}
                             className="carousel slide container-fluid"
                             data-ride="carousel"
                             data-interval="8000">
                            <ol className="carousel-indicators">
                                <li data-target={carouselDataTarget} data-slide-to="0" className="active"/>
                                <li data-target={carouselDataTarget} data-slide-to="1"/>
                                <li data-target={carouselDataTarget} data-slide-to="2"/>
                            </ol>
                            <div className="carousel-inner">
                                <MyContext.Consumer>
                                    {({slideShow}) => {
                                        if (slideShow === false) {
                                            return (
                                                <>
                                                    Loading...
                                                </>
                                            )
                                        } else {
                                            let imageMapping = slideShow[0]["Image_and_captions"];
                                            if (slideShow !== null && slideShow !== undefined) {
                                                return (
                                                    Object.keys(slideShow[0]["Image_and_captions"]).map(imageKey => {
                                                        let image = imageMapping[imageKey];
                                                        if (imageKey === "0") {
                                                            return (
                                                                createCarouselItem(image["Link"], image["Caption"],
                                                                    image["Description"], 'active')
                                                            )
                                                        } else {
                                                            return (createCarouselItem(image["Link"], image["Caption"],
                                                                image["Description"], ''))
                                                        }
                                                    })

                                                )
                                            }

                                        }
                                    }}
                                </MyContext.Consumer>

                                <span className='d-none d-lg-block d-xl-block'>
                                            <a className="carousel-control-prev" href={carouselDataTarget} role="button"
                                               data-slide="prev">
                                                <span className="carousel-control-prev-icon" aria-hidden="true"/>
                                                <span className="sr-only">Previous</span>
                                            </a>
                                        </span>

                                <span className='d-none d-lg-block d-xl-block'>
                                            <a className="carousel-control-next" href={carouselDataTarget} role="button"
                                               data-slide="next">
                                                <span className="carousel-control-next-icon" aria-hidden="true"/>
                                                <span className="sr-only">Next</span>
                                            </a>
                                        </span>
                            </div>

                            <br/>

                            <div className="modal fade" id="exampleModalLong" tabIndex="-1" role="dialog"
                                 aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <h4 id={"homePageModalHeader"}>About ASSCT</h4>
                                            The American Society of Sugarcane Technologists (ASSCT) is a society
                                            interested
                                            in
                                            the general study of the cane sugar industry in the United States and the
                                            dissemination of information to members of the organization through meetings
                                            and
                                            publications. The ASSCT is comprised of two major branches, the Florida
                                            Division
                                            and
                                            the Louisiana Division. Members of the ASSCT generally associate themselves
                                            with
                                            one
                                            of two membership groups, Agriculture or Manufacturing. The Agriculture
                                            group
                                            includes individuals interested principally in the growing and harvesting of
                                            sugarcane. The Manufacturing group includes individuals interested primarily
                                            in
                                            the
                                            extraction and processing of sucrose from sugarcane.<br/><br/>

                                            The Florida and Louisiana
                                            Divisions of the ASSCT meet together once annually during June, with the
                                            Florida
                                            Division hosting this joint meeting during even-numbered years and the
                                            Louisiana
                                            Division hosting the meeting during odd-numbered years. These annual
                                            meetings
                                            are
                                            held over a two-day period during which members present papers on various
                                            subjects
                                            associated with the growing and processing of sugarcane for sugar. Each of
                                            the
                                            two
                                            ASSCT Divisions holds a separate meeting each year in their own home-state.
                                            The
                                            ASSCT publishes an annual journal called The Journal of the American Society
                                            of
                                            Sugar Cane Technologists in which scientific papers from the annual joint
                                            meeting
                                            are published. The society also supports academic activities at the
                                            undergraduate
                                            and graduate levels. Society activities are funded through membership dues
                                            and
                                            donations. Donations can be made by check or through the society's PayPal
                                            account.
                                            Thank you.
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary"
                                                    data-dismiss="modal">Close
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="App-Component">
                    <div className={"HomePage"}>
                        <div className={"row"}>
                            <div className={"col-3"}/>

                            <div className={"col-5"}>
                                <h2 className={"PageHeader"}>American Society of Sugar Cane Technologists<br/>
                                </h2>
                            </div>
                            <div className={"donationButton col"}>
                                <button className={"btn btn-warning donateButton"}>
                                    <NavLink exact to={"/donate"}> Pay or Donate</NavLink>
                                </button>
                            </div>
                        </div>

                        <h6 id="subHeadLine">
                            Dedicated to the advancement of the mainland sugarcane industry in the United States of
                            America
                        </h6>


                        <div>
                            <div id="aboutText">
                                <p>
                                    The American Society of Sugarcane Technologists (ASSCT) is a society interested in
                                    the
                                    general
                                    study of the cane sugar industry in the United States and the dissemination of
                                    information
                                    to
                                    members of the organization through meetings and publications. The ASSCT is
                                    comprised of
                                    two
                                    major branches, the Florida Division and the Louisiana Division...
                                    <a data-toggle="modal"
                                       data-target="#exampleModalLong" id={"learnMoreText"}>
                                        <br/>Click to Learn More . . .
                                    </a>

                                </p>
                            </div>
                        </div>

                        <div id={carouselId}
                             className="carousel slide container-fluid"
                             data-ride="carousel"
                             data-interval="8000">
                            <ol className="carousel-indicators">
                                <li data-target={carouselDataTarget} data-slide-to="0" className="active"/>
                                <li data-target={carouselDataTarget} data-slide-to="1"/>
                                <li data-target={carouselDataTarget} data-slide-to="2"/>
                            </ol>
                            <div className="carousel-inner">
                                <MyContext.Consumer>
                                    {({slideShow}) => {
                                        if (slideShow === false) {
                                            return (
                                                <>
                                                    Loading...
                                                </>
                                            )
                                        } else {
                                            let imageMapping = slideShow[0]["Image_and_captions"];
                                            if (slideShow !== null && slideShow !== undefined) {
                                                return (
                                                    Object.keys(slideShow[0]["Image_and_captions"]).map(imageKey => {
                                                        let image = imageMapping[imageKey];
                                                        if (imageKey === "0") {
                                                            return (
                                                                createCarouselItem(image["Link"], image["Caption"],
                                                                    image["Description"], 'active')
                                                            )
                                                        } else {
                                                            return (createCarouselItem(image["Link"], image["Caption"],
                                                                image["Description"], ''))
                                                        }
                                                    })

                                                )
                                            }

                                        }
                                    }}
                                </MyContext.Consumer>

                                <span className='d-none d-lg-block d-xl-block'>
                                            <a className="carousel-control-prev" href={carouselDataTarget} role="button"
                                               data-slide="prev">
                                                <span className="carousel-control-prev-icon" aria-hidden="true"/>
                                                <span className="sr-only">Previous</span>
                                            </a>
                                        </span>

                                <span className='d-none d-lg-block d-xl-block'>
                                            <a className="carousel-control-next" href={carouselDataTarget} role="button"
                                               data-slide="next">
                                                <span className="carousel-control-next-icon" aria-hidden="true"/>
                                                <span className="sr-only">Next</span>
                                            </a>
                                        </span>
                            </div>

                            <br/>

                            <div className="modal fade" id="exampleModalLong" tabIndex="-1" role="dialog"
                                 aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <h4 id={"homePageModalHeader"}>About ASSCT</h4>
                                            THE AMERICAN SOCIETY OF SUGAR CANE TECHNOLOGISTS (ASSCT) is a society
                                            interested
                                            in
                                            the general study of the cane sugar industry in the United States and the
                                            dissemination of information to members of the organization through meetings
                                            and
                                            publications. The ASSCT is comprised of two major branches, the Florida
                                            Division
                                            and
                                            the Louisiana Division. Members of the ASSCT generally associate themselves
                                            with
                                            one
                                            of two membership groups, Agriculture or Manufacturing. The Agriculture
                                            group
                                            includes individuals interested principally in the growing and harvesting of
                                            sugarcane. The Manufacturing group includes individuals interested primarily
                                            in
                                            the
                                            extraction and processing of sucrose from sugarcane.<br/><br/>

                                            The Florida and Louisiana
                                            Divisions of the ASSCT meet together once annually during June, with the
                                            Florida
                                            Division hosting this joint meeting during even-numbered years and the
                                            Louisiana
                                            Division hosting the meeting during odd-numbered years. These annual
                                            meetings
                                            are
                                            held over a two-day period during which members present papers on various
                                            subjects
                                            associated with the growing and processing of sugarcane for sugar. Each of
                                            the
                                            two
                                            ASSCT Divisions holds a separate meeting each year in their own home-state.
                                            The
                                            ASSCT publishes an annual journal called The Journal of the American Society
                                            of
                                            Sugar Cane Technologists in which scientific papers from the annual joint
                                            meeting
                                            are published. The society also supports academic activities at the
                                            undergraduate
                                            and graduate levels. Society activities are funded through membership dues
                                            and
                                            donations. Donations can be made by check or through the society's PayPal
                                            account.
                                            Thank you.
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary"
                                                    data-dismiss="modal">Close
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }


    componentDidMount() {
        if (window.screen.width <= 991) {
            let carouselSwipeListener = new Hammer(document.getElementById("homePageCarousel"));
            carouselSwipeListener.on('panleft', swipeLeft);
            carouselSwipeListener.on('panright', swipeRight);
        }

    }
}

function createCarouselItem(image, title, caption, activeStatus) {
    return (
        <div className={"carousel-item " + activeStatus}>
            <img className="d-block w-100 img-fluid" src={image} alt="First slide"/>
            <div className="carousel-caption d-none d-md-block">
                <h4>{title}</h4>
                <p>{caption}</p>
            </div>
        </div>
    )
}

function swipeRight() {
    $(document).ready(function () {
        $("#homePageCarousel").carousel('prev');
    });
}

function swipeLeft() {
    $(document).ready(function () {
        $("#homePageCarousel").carousel('next');
    });
}

export default Home;
