import React from 'react';


import '../Component.css';
import './HonoraryMembers.css'
import {MyContext} from "../../config/Theme-Context";

class HonoraryMembers extends React.Component {


    render() {

        if (window.screen.width >= 991) {
            return (
                <div className="App-Component">
                    <div id={"honoraryMembersPage"}>
                        <h2 className={"PageHeader"}>Honorary Members
                            of the
                            American Society of Sugar Cane Technologists</h2>
                        <p id="honoraryMemberPageHeader">
                            Honorary membership shall be conferred on any individual who has distinguished himself or
                            herself in the sugar industry and is elected by a majority vote of the Joint Executive
                            Committee. Honorary membership shall be exempt from dues and entitled to all the privileges
                            of
                            active membership. Each Division may have up to 15 Honorary Members. In addition, there may
                            be
                            up to eight Honorary members assigned to the two Divisions jointly. The following is a
                            current
                            list of the Honorary members of the American Society of Sugar Cane Technologists:
                        </p>

                        <h4 className={"honararyHeader"}>
                            Honorary Members
                        </h4>


                        <div className={"row"}>
                            <div className="col-4 honoree">
                                <h5 className={"divisionName"}>Florida Division</h5>
                                <MyContext.Consumer>
                                    {({honoraryMembers}) => {
                                        if (honoraryMembers === false) {
                                            return (
                                                <h5>
                                                    Loading...
                                                </h5>
                                            )
                                        } else {
                                            return (
                                                <>
                                                    {honoraryMembers["Florida Division"][0].Members.map(member => {
                                                        return (
                                                            <>
                                                                {member}<br/>
                                                            </>
                                                        )
                                                    })}
                                                </>
                                            )
                                        }
                                    }
                                    }

                                </MyContext.Consumer>

                            </div>

                            <div className="col-4 honoree">
                                <h5 className={"divisionName"}>Joint Division</h5>
                                <MyContext.Consumer>
                                    {({honoraryMembers}) => {
                                        if (honoraryMembers === false) {
                                            return (
                                                <h5>
                                                    Loading...
                                                </h5>
                                            )
                                        } else {
                                            return (
                                                <>
                                                    {honoraryMembers["Joint Division"][0].Members.map(member => {
                                                        return (
                                                            <>
                                                                {member}<br/>
                                                            </>
                                                        )
                                                    })}
                                                </>
                                            )
                                        }
                                    }
                                    }

                                </MyContext.Consumer>
                            </div>

                            <div className="col-4 honoree">
                                <h5 className={"divisionName"}>Louisiana Division</h5>
                                <div className={"row"}>
                                    <MyContext.Consumer>
                                        {({honoraryMembers}) => {
                                            if (honoraryMembers === false) {
                                                return (
                                                    <h5>
                                                        Loading...
                                                    </h5>
                                                )
                                            } else {
                                                let allMembers = honoraryMembers["Louisiana Division"][0]["Members"];
                                                let half_length = Math.ceil(allMembers.length / 2);

                                                let leftSide = allMembers.slice(0, half_length);
                                                let rightSide = allMembers.slice(half_length, half_length * 2);

                                                return (
                                                    <>
                                                        <div className={"col-6"}>
                                                            {leftSide.map(member => {
                                                                return (
                                                                    <>
                                                                        {member}<br/>
                                                                    </>
                                                                )
                                                            })}
                                                        </div>

                                                        <div className={"col-6"}>
                                                            {rightSide.map(member => {
                                                                return (
                                                                    <>
                                                                        {member}<br/>
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </>


                                                )
                                            }
                                        }
                                        }

                                    </MyContext.Consumer>
                                </div>

                            </div>
                        </div>
                        <div className={"honararyHeader"}>
                            <h5>
                                In Memoriam
                            </h5>
                            <h6>
                                In memory of deceased Honorary Members*
                            </h6>
                        </div>
                        <div className={"row"}>
                            <div className="col-4 honoree">
                                <h6 className={"divisionName"}>Florida Division</h6>
                                <div className={"row"}>
                                    <MyContext.Consumer>
                                        {({inMemoriam}) => {
                                            if (inMemoriam === false) {
                                                return (
                                                    <h5>
                                                        Loading...
                                                    </h5>
                                                )
                                            } else {
                                                let allMembers = inMemoriam["Florida Division"][0]["Members"];
                                                let half_length = Math.ceil(allMembers.length / 2);

                                                let leftSide = allMembers.slice(0, half_length);
                                                let rightSide = allMembers.slice(half_length, half_length * 2);

                                                return (
                                                    <>
                                                        <div className={"col-6"}>
                                                            {leftSide.map(member => {
                                                                return (
                                                                    <>
                                                                        {member}<br/>
                                                                    </>
                                                                )
                                                            })}
                                                        </div>

                                                        <div className={"col-6"}>
                                                            {rightSide.map(member => {
                                                                return (
                                                                    <>
                                                                        {member}<br/>
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </>


                                                )
                                            }
                                        }
                                        }

                                    </MyContext.Consumer>
                                </div>
                            </div>

                            <div className="col-4 honoree">
                                <h6 className={"divisionName"}>Joint Division</h6>
                                <MyContext.Consumer>
                                    {({inMemoriam}) => {
                                        if (inMemoriam === false) {
                                            return (
                                                <h5>
                                                    Loading...
                                                </h5>
                                            )
                                        } else {
                                            return (
                                                <>
                                                    {inMemoriam["Joint Division"][0].Members.map(member => {
                                                        if (typeof member === "string") {
                                                            return (
                                                                <>
                                                                    {member}<br/>
                                                                </>
                                                            )
                                                        } else {
                                                            let name = Object.keys(member)[0]
                                                            return (
                                                                <>
                                                                    <a href={member[name]}>{name}</a>
                                                                    <br/>

                                                                </>
                                                            )
                                                        }

                                                    })}
                                                </>
                                            )
                                        }
                                    }
                                    }

                                </MyContext.Consumer>


                            </div>

                            <div className="col-4 honoree">
                                <h6 className={"divisionName"}>Louisiana Division</h6>

                                <div className={"row"}>
                                    <MyContext.Consumer>
                                        {({inMemoriam}) => {
                                            if (inMemoriam === false) {
                                                return (
                                                    <h5>
                                                        Loading...
                                                    </h5>
                                                )
                                            } else {
                                                let allMembers = inMemoriam["Louisiana Division"][0]["Members"];
                                                let half_length = Math.ceil(allMembers.length / 2);

                                                let leftSide = allMembers.slice(0, half_length);
                                                let rightSide = allMembers.slice(half_length, half_length * 2);

                                                return (
                                                    <>
                                                        <div className={"col-6"}>
                                                            {leftSide.map(member => {
                                                                return (
                                                                    <>
                                                                        {member}<br/>
                                                                    </>
                                                                )
                                                            })}
                                                        </div>

                                                        <div className={"col-6"}>
                                                            {rightSide.map(member => {
                                                                return (
                                                                    <>
                                                                        {member}<br/>
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </>


                                                )
                                            }
                                        }
                                        }

                                    </MyContext.Consumer>
                                </div>

                            </div>
                            <h6>
                                <i>
                                    *Beginning with 1993 Honorary Members
                                </i>
                            </h6>
                        </div>
                    </div>

                </div>
            );
        } else {
            return (
                <div className="App-Component">
                    <div id={"honoraryMembersPage"}>
                        <h2 className={"PageHeader"}>Honorary Members
                            of the
                            American Society of Sugar Cane Technologists</h2>
                        <p id="honoraryMemberPageHeader">
                            Honorary membership shall be conferred on any individual who has distinguished himself or
                            herself in the sugar industry and is elected by a majority vote of the Joint Executive
                            Committee. Honorary membership shall be exempt from dues and entitled to all the privileges
                            of
                            active membership. Each Division may have up to 15 Honorary Members. In addition, there may
                            be
                            up to eight Honorary members assigned to the two Divisions jointly. The following is a
                            current
                            list of the Honorary members of the American Society of Sugar Cane Technologists:
                        </p>

                        <h4 className={"honararyHeader"}>
                            Honorary Members
                        </h4>

                        <div className={"row"}>
                            <div className="col-4 honoree">
                                <h5 className={"divisionName"}>Florida Division</h5>
                                <MyContext.Consumer>
                                    {({honoraryMembers}) => {
                                        if (honoraryMembers === false) {
                                            return (
                                                <h5>
                                                    Loading...
                                                </h5>
                                            )
                                        } else {
                                            return (
                                                <>
                                                    {honoraryMembers["Florida Division"][0].Members.map(member => {
                                                        return (
                                                            <>
                                                                {member}<br/>
                                                            </>
                                                        )
                                                    })}
                                                </>
                                            )
                                        }
                                    }
                                    }

                                </MyContext.Consumer>
                            </div>

                            <div className="col-4 honoree">
                                <h5 className={"divisionName"}>Joint Division</h5>
                                <MyContext.Consumer>
                                    {({honoraryMembers}) => {
                                        if (honoraryMembers === false) {
                                            return (
                                                <h5>
                                                    Loading...
                                                </h5>
                                            )
                                        } else {
                                            return (
                                                <>
                                                    {honoraryMembers["Joint Division"][0].Members.map(member => {
                                                        return (
                                                            <>
                                                                {member}
                                                                <br/>
                                                            </>
                                                        )
                                                    })}
                                                </>
                                            )
                                        }
                                    }
                                    }

                                </MyContext.Consumer>
                            </div>

                            <div className="col-4 honoree">
                                <h5 className={"divisionName"}>Louisiana Division</h5>

                                <MyContext.Consumer>
                                    {({honoraryMembers}) => {
                                        if (honoraryMembers === false) {
                                            return (
                                                <h5>
                                                    Loading...
                                                </h5>
                                            )
                                        } else {
                                            return (
                                                <>
                                                    {honoraryMembers["Louisiana Division"][0].Members.map(member => {
                                                        return (
                                                            <>
                                                                {member}<br/>
                                                            </>
                                                        )
                                                    })}
                                                </>
                                            )
                                        }
                                    }
                                    }

                                </MyContext.Consumer>
                            </div>
                        </div>

                        <div className={"honararyHeader"}>
                            <h5>
                                In Memoriam
                            </h5>
                            <h6>
                                In memory of deceased Honorary Members*
                            </h6>
                        </div>
                        <div className={"row"}>
                            <div className="col-4 honoree">
                                <h6 className={"divisionName"}>Florida Division</h6>
                                <MyContext.Consumer>
                                    {({inMemoriam}) => {
                                        if (inMemoriam === false) {
                                            return (
                                                <h5>
                                                    Loading...
                                                </h5>
                                            )
                                        } else {
                                            return (
                                                <>
                                                    {inMemoriam["Florida Division"][0].Members.map(member => {
                                                        if (typeof member === "string") {
                                                            return (
                                                                <>
                                                                    {member}<br/>
                                                                </>
                                                            )
                                                        } else {
                                                            let name = Object.keys(member)[0]
                                                            return (
                                                                <>
                                                                    <a href={member[name]}>{name}</a>
                                                                    <br/>

                                                                </>
                                                            )
                                                        }

                                                    })}
                                                </>
                                            )
                                        }
                                    }
                                    }

                                </MyContext.Consumer>

                            </div>

                            <div className="col-4 honoree">
                                <h6 className={"divisionName"}>Joint Division</h6>
                                <MyContext.Consumer>
                                    {({inMemoriam}) => {
                                        if (inMemoriam === false) {
                                            return (
                                                <h5>
                                                    Loading...
                                                </h5>
                                            )
                                        } else {
                                            return (
                                                <>
                                                    {inMemoriam["Joint Division"][0].Members.map(member => {
                                                        if (typeof member === "string") {
                                                            return (
                                                                <>
                                                                    {member}<br/>
                                                                </>
                                                            )
                                                        } else {
                                                            let name = Object.keys(member)[0]
                                                            return (
                                                                <>
                                                                    <a href={member[name]}>{name}</a>
                                                                    <br/>

                                                                </>
                                                            )
                                                        }

                                                    })}
                                                </>
                                            )
                                        }
                                    }
                                    }

                                </MyContext.Consumer>


                            </div>

                            <div className="col-4 honoree">
                                <h6 className={"divisionName"}>Louisiana Division</h6>
                                <MyContext.Consumer>
                                    {({inMemoriam}) => {
                                        if (inMemoriam === false) {
                                            return (
                                                <h5>
                                                    Loading...
                                                </h5>
                                            )
                                        } else {
                                            return (
                                                <>
                                                    {inMemoriam["Louisiana Division"][0].Members.map(member => {
                                                        if (typeof member === "string") {
                                                            return (
                                                                <>
                                                                    {member}<br/>
                                                                </>
                                                            )
                                                        } else {
                                                            let name = Object.keys(member)[0]
                                                            return (
                                                                <>
                                                                    <a href={member[name]}>{name}</a>
                                                                    <br/>

                                                                </>
                                                            )
                                                        }

                                                    })}
                                                </>
                                            )
                                        }
                                    }
                                    }

                                </MyContext.Consumer>
                            </div>
                            <h6>
                                <i>
                                    *Beginning with 1993 Honorary Members
                                </i>
                            </h6>
                        </div>
                    </div>
                </div>
            )
        }


    }


}


export default HonoraryMembers;
